// Arquivo criado: 23/07/2023 às 21:17
import React from 'react'
import * as S from './styles'

type Props = React.InputHTMLAttributes<HTMLInputElement>

export const CustomInput: React.FC<Props> = ({
  ...props
}) => (
  <S.CustomInput {...props}/>
)
