import { type IGlobalMessage } from '../../interfaces/IGlobalMessage'
import { type SetOpenGlobalMessageAction } from '../actions/globalMessage.action'

interface State {
  data: IGlobalMessage
}

const initialState: State = {
  data: {
    message: '',
    type: 'error'
  }
}

export const globalMessageReducer = (
  state = initialState,
  action: SetOpenGlobalMessageAction
): State => {
  switch (action.type) {
  case 'SET_OPEN_GLOBAL_MESSAGE':
    return {
      ...state,
      data: action.payload
    }
  default:
    return state
  }
}
