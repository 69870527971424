import React from 'react'
import { RoutesController } from './routesController'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { theme } from './config/theme'
import { GlobalMessage } from './components/GlobalMessage'
import { SnackbarProvider } from 'notistack'
import { BackDropLoading } from './components/BackDropLoading'

const App = (): JSX.Element => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider theme={theme}>
            <RoutesController />
            <SnackbarProvider maxSnack={2} autoHideDuration={2000}>
              <GlobalMessage />
              <BackDropLoading />
            </SnackbarProvider>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  )
}

// eslint-disable-next-line import/no-default-export
export default App
