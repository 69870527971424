// Arquivo criado: 02/10/2023 às 22:35
import React, { type SyntheticEvent } from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { type IBoard } from '../../interfaces/IBoard'
import { type IManagement } from '../../interfaces/IManagement'
import { type IWorkCategory } from '../../interfaces/IWorkCategory'
import { type IWorkModel } from '../../interfaces/IWorkModel'
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'
import { theme } from '../../config/theme'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { urlFile } from '../../utils/urlFile.util'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import Autocomplete from '@mui/material/Autocomplete'
import DialogTitle from '@mui/material/DialogTitle'

interface Authors {
  boardId: number
  cellphone: string
  email: string
  managementId: number
  name: string
}

interface Evaluator {
  id: number
  email: string
  name: string
}

interface Status {
  id: number
  label: string
}

interface IWorkStatus {
  status: Status
  aditionalInfo: string
  createdAt: Date
}

interface Works {
  apresentation: string | null
  authors: Authors[]
  board: {
    id: number
    label: string
  }
  boardId: number
  category: {
    id: number
    label: string
  }
  categoryId: number
  code: string
  confirmationSubmit: boolean
  costAnalysis: boolean
  createdAt: string
  deleted: boolean
  diversePopulations: boolean
  evaluator: Evaluator | null
  evaluatorId: number | null
  eventId: number
  id: number
  local: string
  management: {
    id: number
    label: string
  }
  managementId: number
  model: {
    id: number
    label: string
  }
  modelId: number
  nurse: boolean
  status: Status
  statusId: number
  summary: string
  tags: string
  title: string
  updatedAt: string
  userId: number
  file: {
    id: number
    name: string
  }
}

/* interface NotifyEvaluator {
  id: number
  name: string
} */

export const AssignWorks = (): JSX.Element => {

  const event = useSelector((state: RootState) => state.event.data)
  const dispatch = useDispatch()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [works, setWorks] = React.useState<Works[]>([])
  const [isSorted, setIsSorted] = React.useState(false)
  const [get, setGet] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [boards, setBoards] = React.useState<IBoard[]>([])
  // const [selectedBoard, setSelectedBoard] = React.useState(0)
  const [shownBoard, setShownBoard] = React.useState('')
  const [managements, setManagements] = React.useState<IManagement[]>([])
  const [shownManagement, setShownManagement] = React.useState('')
  const [categories, setCategories] = React.useState<IWorkCategory[]>([])
  // const [selectedCategory, setSelectedCategory] = React.useState(0)
  const [shownCategory, setShownCategory] = React.useState('')
  const [models, setModels] = React.useState<IWorkModel[]>([])
  const [shownModel, setShownModel] = React.useState('')
  const [evaluators, setEvaluators] = React.useState<Evaluator[]>([])
  const [selectedEvaluator, setSelectedEvaluator] = React.useState(0)
  const [shownEvaluator, setShownEvaluator] = React.useState('')
  const [shownFilterEvaluator, setShownFilterEvaluator] = React.useState('')
  const [workStatus, setWorkStatus] = React.useState<Status[]>([])
  const [shownStatus, setShownStatus] = React.useState('')
  const [selectedItems, setSelectedItems] = React.useState<number[]>([])
  const [search, setSearch] = React.useState('')
  const [shownSearch, setShownSearch] = React.useState('')
  const [showButton, setShowButton] = React.useState(false)
  const [idEvaluatorForNotification, setIdEvaluatorForNotification] = React.useState(0)
  const [shownEvaluatorForNotification, setShownEvaluatorForNotification] = React.useState('')
  const [selectAll, setSelectAll] = React.useState(false)

  const [fileUrl, setFileUrl] = React.useState<string | null>(null)
  const [fileInBase, setFileInBase] = React.useState<{ id: number, name: string } | null>(null)
  const [selectedWork, setSelectedWork] = React.useState<Works | null>(null)
  const [openWorkInfo, setOpenWorkInfo] = React.useState(false)

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [deletedItem, setDeleteItem] = React.useState<Works | null>(null)

  const [tagsArray, setTagsArray] = React.useState<string[]>([])
  const [noEvaluators, setNoEvaluators] = React.useState('')
  const [sortedEvaluators, setSortedEvaluators] = React.useState<Evaluator[]>([])
  const [clearEvaluator, setClearEvaluator] = React.useState(0)
  // const [notificationEvaluators, setNotificationEvaluators] = React.useState<NotifyEvaluator[]>([])
  // const [cleanEvaluators, setCleanEvaluators] = React.useState(false)

  const [openStatusModal, setOpenStatusModal] = React.useState(false)
  const [statusItem, setStatusItem] = React.useState<Works | null>(null)
  const [shownNewStatus, setShownNewStatus] = React.useState('')
  const [newStatus, setNewStatus] = React.useState(0)
  const [openSelectedStatusModal, setOpenSelectedStatusModal] = React.useState(false)
  const [workStatusInfo, setWorkStatusInfo] = React.useState<IWorkStatus[]>([])

  const [hasBoard, setHasBoard] = React.useState(false)
  const [hasManagement, setHasManagement] = React.useState(false)
  const [hasCategory, setHasCategory] = React.useState(false)
  const [hasModel, setHasModel] = React.useState(false)
  const [hasEvaluator, setHasEvaluator] = React.useState(false)
  const [hasStatus, setHasStatus] = React.useState(false)

  const [allWorks, setAllWorks] = React.useState<Works[]>([])
  const [boardWorks, setBoardWorks] = React.useState<Works[]>([])
  const [managementWorks, setManagementWorks] = React.useState<Works[]>([])
  const [categoryWorks, setCategoryWorks] = React.useState<Works[]>([])
  const [modelWorks, setModelWorks] = React.useState<Works[]>([])
  const [evaluatorWorks, setEvaluatorWorks] = React.useState<Works[]>([])
  const [statusWorks, setStatusWorks] = React.useState<Works[]>([])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'Distribuição de trabalhos'

      if (!event) return

      // setLoading(true)
      const response = await backEnd('GET', `works/event/${event.id}`)
      // setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setAllWorks(response.data)
      setWorks(response.data)

      if (get) {
        setGet(false)
      }

    }
    void getData()
  }, [dispatch, event, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const resBoards = await backEnd('GET', 'boards')
      const resCategories = await backEnd('GET', 'works/categories')

      if (!resBoards.ok || !resCategories.ok) {

        const message = resBoards.ok ? resCategories.msg : resBoards.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setBoards(resBoards.data)
      setCategories(resCategories.data)
      setManagements([])
    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const resEvaluator = await backEnd('GET', 'evaluators')
      const resStatus = await backEnd('GET', 'works/status')

      if (!resEvaluator.ok || !resStatus.ok) {

        const message = resEvaluator.ok ? resStatus.msg : resEvaluator.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setEvaluators(resEvaluator.data)
      setWorkStatus(resStatus.data)

    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {

    setSortedEvaluators(evaluators.sort(function (a, b) {
      return a.name.localeCompare(b.name, 'pt', { sensitivity: 'base' })
    }))

  }, [evaluators])

  React.useEffect(() => {

    let filteredArray = allWorks
    setShownSearch('')

    if (hasBoard) {
      const arr: Works[] = []
      for (let i = 0; i < boardWorks.length; i++) {
        if (filteredArray.filter(item => item.id === boardWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === boardWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasManagement) {
      const arr: Works[] = []
      for (let i = 0; i < managementWorks.length; i++) {
        if (filteredArray.filter(item => item.id === managementWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === managementWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasCategory) {
      const arr: Works[] = []
      for (let i = 0; i < categoryWorks.length; i++) {
        if (filteredArray.filter(item => item.id === categoryWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === categoryWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasModel) {
      const arr: Works[] = []
      for (let i = 0; i < modelWorks.length; i++) {
        if (filteredArray.filter(item => item.id === modelWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === modelWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasEvaluator) {
      const arr: Works[] = []
      for (let i = 0; i < evaluatorWorks.length; i++) {
        if (filteredArray.filter(item => item.id === evaluatorWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === evaluatorWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasStatus) {
      const arr: Works[] = []
      for (let i = 0; i < statusWorks.length; i++) {
        if (filteredArray.filter(item => item.id === statusWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === statusWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    setWorks(filteredArray)

  }, [allWorks, boardWorks, hasBoard, managementWorks, hasManagement, categoryWorks, hasCategory, modelWorks, hasModel, evaluatorWorks, hasEvaluator, statusWorks, hasStatus])

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3b81f725',
      color: theme.palette.common.white,
      '&:last-child': {
        borderTopRightRadius: 6
      },
      '&:first-of-type': {
        borderTopLeftRadius: 6
      }
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const handleSelectBoard = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const board = boards.find(item => item.id === id)
    // setSelectedBoard(id)
    setShownManagement('')
    // setSelectedManagement(0)
    setShownCategory('')
    setShownModel('')

    if (!board) return
    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}?boardId=${board.id}`)
    // setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }
    const newArr: Works[] = [] // aqui passa um filter no allWorks
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasBoard(true)
    setBoardWorks(newArr)
    // setWorks(newArr) // acho q isso não vai precisar existir, pq has Board vai ser dependência do useEffect

    const response2 = await backEnd('GET', `managements/board/${board.id}`)
    setShownBoard(board.label)

    if (!response2.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response2.msg
      }))
      return
    }

    setManagements(response2.data)

  }

  const handleSelectManagement = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const management = managements.find(item => item.id === id)
    // setSelectedManagement(id)
    if (!management) return
    setShownManagement(management.label)

    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}?managementId=${management.id}`)
    // setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasManagement(true)
    setManagementWorks(newArr)
    // setWorks(newArr)
  }

  const handleSelectCategory = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const category = categories.find(item => item.id === id)
    // setSelectedCategory(id)
    setShownModel('')
    // setSelectedModel(0)
    // setShownBoard('')
    // setShownManagement('')

    if (!category) return
    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}?categoryId=${category.id}`)
    // setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasCategory(true)
    setCategoryWorks(newArr)
    // setWorks(newArr)

    const response2 = await backEnd('GET', `works/models/category/${category.id}`)
    setShownCategory(category.label)

    if (!response2.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response2.msg
      }))
      return
    }

    setModels(response2.data)
  }

  const handleSelectModel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    // setSelectedModel(id)
    const model = models.find(item => item.id === id)
    if (!model) return
    setShownModel(model.label)

    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}?modelId=${model.id}`)
    // setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasModel(true)
    setModelWorks(newArr)
    // setWorks(newArr)
  }

  const handleSelectFilterEvaluator = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const filterEvaluator = sortedEvaluators.find(item => item.id === id)

    if (!filterEvaluator) return
    if (!event) return

    setShownFilterEvaluator(filterEvaluator.name)

    const response = await backEnd('GET', `works/event/${event.id}?evaluatorId=${filterEvaluator.id}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasEvaluator(true)
    setEvaluatorWorks(newArr)
    // setWorks(newArr)
  }

  const handleSelectStatus = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const status = workStatus.find(item => item.id === id)

    if (!status) return
    if (!event) return

    setShownStatus(status.label)

    const response = await backEnd('GET', `works/event/${event.id}?statusId=${status.id}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasStatus(true)
    setStatusWorks(newArr)
    // setWorks(newArr)
  }

  /* const handleSelectEvaluator = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const evaluator = evaluators.find(item => item.id === id)
    setSelectedEvaluator(id)
    setIdEvaluatorForNotification(id)

    if (!evaluator) return

    setShownEvaluator(evaluator.name)
    setShownEvaluatorForNotification(evaluator.name)
    // setNotificationEvaluators([
      // ...notificationEvaluators,
      // { id: evaluator.id, name: evaluator.name }
    // ])
    setShowButton(false)
  } */

  /*  const handleSelectEvaluatorByName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(e.target.value)
    const name = e.target.value
    const evaluator = evaluators.find(item => item.name === name)

    if (!evaluator) return
    setSelectedEvaluator(evaluator.id)
    setIdEvaluatorForNotification(evaluator.id)
    console.log(evaluator)

    setShownEvaluator(evaluator.name)
    setShownEvaluatorForNotification(evaluator.name)

    setShowButton(false)
  } */

  const handleAutocompleteChange = (event: SyntheticEvent<Element, Event>, value: Evaluator | null): void => {
    const selected = value
    if (!selected) return
    const evaluator = evaluators.find(item => item.name === selected.name)

    if (!evaluator) return
    setSelectedEvaluator(evaluator.id)
    setIdEvaluatorForNotification(evaluator.id)

    setShownEvaluator(evaluator.name)
    setShownEvaluatorForNotification(evaluator.name)

    setShowButton(false)
  }

  const handleNoEvaluators = (): void => {
    const noEvaluator = works.filter(item => (item.evaluator === null))
    setNoEvaluators('Sem avaliador')
    setWorks(noEvaluator)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value)
  }

  const onClickSearch = (): void => {
    clearFilters()
    const filterdWorks = Number(search) ? allWorks.filter(item => item.code.includes(search.toLowerCase())) : allWorks.filter(item => item.title.toLowerCase().includes(search.toLowerCase()))
    setWorks(filterdWorks)
    setShownSearch(search)
    setSearch('')
  }

  /* const handleNoFilters = async (): Promise<void> => {

    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setWorks(response.data)
    setShownBoard('')
    setShownCategory('')
    setShownFilterEvaluator('')
    setShownManagement('')
    setShownModel('')
    setShownStatus('')
    setShownSearch('')
    setSearch('')
    setNoEvaluators('')
  } */

  const clearFilters = (): void => {
    setShownBoard('')
    setShownCategory('')
    setShownFilterEvaluator('')
    setShownManagement('')
    setShownModel('')
    setShownStatus('')
    setNoEvaluators('')
  }

  const handleNoFilters = (): void => {

    setWorks(allWorks)
    setHasBoard(false)
    setHasManagement(false)
    setHasCategory(false)
    setHasModel(false)
    setHasEvaluator(false)
    setHasStatus(false)

    setBoardWorks([])
    setManagementWorks([])
    setCategoryWorks([])
    setModelWorks([])
    setEvaluatorWorks([])
    setStatusWorks([])

    setShownSearch('')
    setSearch('')
    clearFilters()

  }

  const handleSort = (): void => {
    const sortedArray = [...works]
    sortedArray.sort(function (a, b) {
      if (a.evaluator !== null && a.evaluator !== undefined && b.evaluator !== null && b.evaluator !== undefined) {
        if (a.evaluator.name < b.evaluator.name) return -1
        if (a.evaluator.name > b.evaluator.name) return 1
        return 0
      } else if ((a.evaluator === null || a.evaluator === undefined) && (b.evaluator !== null && b.evaluator !== undefined)) {
        return -1
      } else if ((a.evaluator !== null && a.evaluator !== undefined) && (b.evaluator === null || b.evaluator === undefined)) {
        return 1
      } else {
        return 0
      }
    })
    if (isSorted) {
      sortedArray.reverse()
      setIsSorted(false)
    } else {
      setIsSorted(true)
    }
    setWorks(sortedArray)
  }

  const sortTable = (key: string): void => {
    const sortedArray = [...works]
    switch (key) {
    case 'title':
      sortedArray.sort(function (a, b) {
        return a.title.localeCompare(b.title, 'pt', { sensitivity: 'base' })
      })
      break
    case 'code':
      sortedArray.sort(function (a, b) {
        return a.code.localeCompare(b.code, 'pt', { sensitivity: 'base' })
      })
      break
    case 'createdAt':
      sortedArray.sort(function (a, b) {
        return a.createdAt.localeCompare(b.createdAt, 'pt', { sensitivity: 'base' })
      })
      break
    case 'status':
      sortedArray.sort(function (a, b) {
        return a.status.label.localeCompare(b.status.label, 'pt', { sensitivity: 'base' })
      })
      break
    case 'board':
      sortedArray.sort(function (a, b) {
        return a.board.label.localeCompare(b.board.label, 'pt', { sensitivity: 'base' })
      })
      break
    case 'model':
      sortedArray.sort(function (a, b) {
        return a.model.label.localeCompare(b.model.label, 'pt', { sensitivity: 'base' })
      })
      break
    case 'category':
      sortedArray.sort(function (a, b) {
        return a.category.label.localeCompare(b.category.label, 'pt', { sensitivity: 'base' })
      })
      break
    default:
      return
    }

    if (isSorted) {
      sortedArray.reverse()
      setIsSorted(false)
    } else {
      setIsSorted(true)
    }
    setWorks(sortedArray)
  }

  const handleSelectWork = (e: React.ChangeEvent<HTMLInputElement>, id: number): void => {
    const _id = id
    const _work = works.find(item => item.id === _id)

    if (!_work) return

    if (selectedItems.includes(_work.id)) {
      setSelectedItems(
        selectedItems.filter(a =>
          a !== _work.id
        )
      )
      return
    }

    setSelectedItems([
      ...selectedItems,
      _work.id
    ])

  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!selectAll) {
      const countStart = page * rowsPerPage
      const countEnd = (works.length - countStart) > rowsPerPage ? rowsPerPage + countStart : works.length
      const select = works.length > rowsPerPage ? works.slice(countStart, countEnd) : works
      const ids = select.map((item: Works) => (
        item.id
      ))
      setSelectedItems(ids)
      setSelectAll(true)
    } else {
      setSelectedItems([])
      setSelectAll(false)
    }

  }

  const handleWorkDistribution = async (): Promise<void> => {

    if (shownEvaluator === '' || shownEvaluator === null) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione um avaliador para continuar',
        type: 'error'
      }))
      return
    }

    if (selectedItems.length < 1) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione pelo menos 1 trabalho continuar',
        type: 'error'
      }))
      return
    }

    const data = {
      evaluatorId: selectedEvaluator,
      worksIds: selectedItems
    }

    setLoading(true)
    const response = await backEnd('PUT', 'works/evaluator', data)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Erro ao distribuir trabalhos. Revise os status dos trabalhos selecionados.',
        type: 'error',
        modal: {
          title: 'Ops..'
        }
      }))
      /* dispatch(setOpenGlobalMessageAction({
        message: response.msg
      })) */
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: `O${selectedItems.length > 1 ? `s ${selectedItems.length}` : ''} trabalho${selectedItems.length > 1 ? 's' : ''} selecionado${selectedItems.length > 1 ? 's' : ''} fo${selectedItems.length > 1 ? 'ram' : 'i'} distribuído${selectedItems.length > 1 ? 's' : ''} com sucesso para o(a) avaliador(a) ${shownEvaluator}.`,
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setGet(true)
    setSelectedEvaluator(0)
    setShownEvaluator('')
    setSelectedItems([])
    setShowButton(true)
    setSelectAll(false)
    setClearEvaluator(clearEvaluator + 1)
  }

  const handleWorkReturn = async (): Promise<void> => {

    if (selectedItems.length < 1) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione pelo menos 1 trabalho continuar',
        type: 'error'
      }))
      return
    }

    if (shownEvaluator) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Você selecionou um avaliador. Se desejar retornar o(s) trabalho(s) selecionado(s) para o banco remova o avaliador da caixa de distribuição',
        type: 'error'
      }))
      return
    }

    const data = {
      worksIds: selectedItems
    }

    setLoading(true)
    const response = await backEnd('PUT', 'works/evaluator/remove', data)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Erro ao retornar trabalhos ao banco. Revise os status dos trabalhos selecionados.',
        type: 'error',
        modal: {
          title: 'Ops..'
        }
      }))
      /* dispatch(setOpenGlobalMessageAction({
        message: response.msg
      })) */
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: `O${selectedItems.length > 1 ? `s ${selectedItems.length}` : ''} trabalho${selectedItems.length > 1 ? 's' : ''} selecionado${selectedItems.length > 1 ? 's' : ''} retorn${selectedItems.length > 1 ? 'aram' : 'ou'} para o banco com sucesso.`,
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setGet(true)
    // setSelectedEvaluator(0) // ver se precisa ou deixa como está
    // setShownEvaluator('') // ver se precisa ou deixa como está
    setSelectedItems([])
    // setShowButton(true)
    setShowButton(false)
    setSelectAll(false)
    // setClearEvaluator(clearEvaluator + 1) // ver se precisa ou deixa como está
  }

  const notifyEvaluator = async (): Promise<void> => {

    if (!idEvaluatorForNotification) return

    const response = await backEnd('POST', `evaluators/${idEvaluatorForNotification}/notify/new-work`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    /* if (notificationEvaluators.length < 1) return

    for (let i = 0; i < notificationEvaluators.length; i++) {
      const response = await backEnd('POST', `evaluators/${notificationEvaluators[i].id}/notify/new-work`)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }

    } */

    dispatch(setOpenGlobalMessageAction({
      message: `Mensagem enviada com sucesso para ${shownEvaluatorForNotification}`,
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setIdEvaluatorForNotification(0)
    setShownEvaluatorForNotification('')
    // setNotificationEvaluators([])
    setShownEvaluator('')
    setShowButton(false)
    setSelectedEvaluator(0)
    setGet(true)
  }

  const handleOpen = async (item: Works): Promise<void> => {

    setSelectedWork(item)
    setFileInBase(item.file)
    // setLoading(true)
    const url = await urlFile(`works/${item.id ?? 0}/file`)
    // setLoading(false)

    if (!url) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Não foi possível encontrar o arquivo'
      }))
      return
    }

    if (url) setFileUrl(url)
    setOpenWorkInfo(true)
    const tagsArray: string[] = item.tags.split(',')
    setTagsArray(tagsArray)

  }

  const handleClose = (): void => {
    setOpenWorkInfo(false)
    setSelectedWork(null)
  }

  const willDelete = (item: Works): void => {
    setDeleteItem(item)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = (): void => {
    setOpenDeleteModal(false)
  }

  const handleDelete = async (item: Works): Promise<void> => {

    if (!event) return
    if (!deletedItem) return

    setLoading(true)
    const response = await backEnd('DELETE', `works/${deletedItem.id}/user/${deletedItem.userId}`)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Trabalho excluído com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setOpenDeleteModal(false)
    setGet(true)

  }

  /*  const deleteNoficationEvaluator = (idx: number): void => {
    setNotificationEvaluators(
      notificationEvaluators.filter(a =>
        a.id !== notificationEvaluators[idx].id)
    )
  } */

  const defaultProps = {
    options: sortedEvaluators,
    getOptionLabel: (option: Evaluator) => option.name
  }

  // fecha modal de alterar status
  const handleCloseStatusModal = (): void => {
    setOpenStatusModal(false)
    setStatusItem(null)
    setShownNewStatus('')
    setNewStatus(0)
  }

  // abre o modal de alterar o status com o trabalho selecionado
  const handleChangeStatus = (item: Works): void => {
    setStatusItem(item)
    setOpenStatusModal(true)
  }

  // pega os valores para alterar o status do trabalho selecionado
  const handleSelectNewStatus = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const status = workStatus.find(item => item.id === id)

    if (!status) return

    setShownNewStatus(status.label)
    setNewStatus(status.id)
  }

  // chama a rota para alterar o status do trabalho selecionado
   // /v1/works/{workId}/status/{statusId}
  const handleNewStatus = async (item: Works): Promise<void> => {

    if (!event) return
    if (!statusItem) return
    if (newStatus === 0) return

    setLoading(true)
    const response = await backEnd('PUT', `works/${statusItem.id}/status/${newStatus}`)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Status do trabalho atualizado com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setOpenStatusModal(false)
    setShownNewStatus('')
    setNewStatus(0)
    setStatusItem(null)
    setGet(true)

  }

  const handleCloseSelectedStatusModal = (): void => {
    setOpenSelectedStatusModal(false)
  }

  const getWorkStatusInfo = async (item: Works): Promise<void> => {

    if (!event) return
    if (!statusItem) return
    setLoading(true)
    const response = await backEnd('GET', `works/${statusItem.id}/status-history`)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setOpenSelectedStatusModal(true)
    setWorkStatusInfo(response.data)

  }

  return (
    <S.Container>
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
      >
        <DialogContent>
          <div>
              Confirma a exclusão do trabalho <strong>{deletedItem?.title}</strong>? Esta ação não poderá ser desfeita
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={deletedItem !== null ? async () => { await handleDelete(deletedItem) } : handleCloseDeleteModal}>Excluir</Button>
          <Button onClick={handleCloseDeleteModal} autoFocus>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openWorkInfo}
        onClose={handleClose}
        fullWidth
        maxWidth={'md'}
      >
        <DialogContent>
          <S.SummaryContainer>
            <S.RowContainer className='summaryRow title blueBorder'>
              <p className='summaryTitle'><strong>Título: </strong>{selectedWork?.title}</p>
            </S.RowContainer>
            <S.RowContainer className='summaryRow noBreak description'>
              <p><strong>Código: </strong>{selectedWork?.code}</p>
              <p className='secondItem'><strong>Diretoria: </strong>{selectedWork?.board.label} / {selectedWork?.management.label}</p>
            </S.RowContainer>
            <S.RowContainer className='summaryRow noBreak description'>
              <p><strong>Categoria: </strong>{selectedWork?.category.label} / {selectedWork?.model.label}</p>
            </S.RowContainer>
            <S.RowContainer className='summaryRow noBreak middleRow'>
              <p><strong className='topic'>Resumo: </strong>{selectedWork?.summary}</p>
            </S.RowContainer>
            <S.RowContainer style={{ alignItems: 'flex-start' }} className='summaryRow middleRow'>
              <S.ColumnContainer width={60}className='authorsStep5'>
                <p className='topic'><strong>Autor{selectedWork?.authors.length !== 1 ? 'es:' : ':'} </strong></p>
                {
                  selectedWork?.authors.map((item, index) => (
                    <S.SingleAuthor key={index}>
                      <p className='author'>{item.name}</p>
                    </S.SingleAuthor>
                  ))
                }
              </S.ColumnContainer>
              <S.ColumnContainer width={40} className='noMarginTop summaryTagsContainer'>
                <p className='topic'><strong>Palavras-chave:</strong></p>
                <S.TagsContainer>
                  {
                    tagsArray.map((item, index) => (
                      <p key={index} className='summaryInLineItem' style={{ marginRight: 5 }}>#{item.trim()}</p>))
                  }
                </S.TagsContainer>
              </S.ColumnContainer>
            </S.RowContainer>
            {
              fileUrl && (
                <S.RowContainer className='summaryRow blueBorder lastRow noBreak'>
                  <p>
                    <strong className='topic'>Arquivo: </strong>
                    <a href={fileUrl} target='_blank' rel="noreferrer">
                      {fileInBase?.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}
                    </a>
                  </p>
                </S.RowContainer>
              )
            }
          </S.SummaryContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openStatusModal}
        onClose={handleCloseStatusModal}
      >
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <p>O trabalho <strong>{statusItem?.title}</strong> atualmente possui o status <strong>{statusItem?.status.label}</strong></p>
          <Button style={{ margin: 'auto' }} variant='outlined' onClick={statusItem !== null ? async () => { await getWorkStatusInfo(statusItem) } : handleCloseStatusModal}>Ver Histórico</Button>
          </div>
          <div>
              <p>Para alterar o status atual deste trabalho, selecione um novo status:</p>
              <TextField className='select' fullWidth size='small' select id='novoStatus' name='novoStatus' value={shownNewStatus} onChange={handleSelectNewStatus} label='Selecionar Status do Trabalho'>
                <MenuItem value={shownNewStatus} style={{ display: 'none' }} disabled>{shownNewStatus}</MenuItem>
                {
                  workStatus.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                  ))
                }
              </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={newStatus === 0} onClick={statusItem !== null ? async () => { await handleNewStatus(statusItem) } : handleCloseStatusModal}>Alterar Status</Button>
          <Button onClick={handleCloseStatusModal} autoFocus>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSelectedStatusModal}
        onClose={handleCloseSelectedStatusModal}
        fullWidth
        maxWidth={'sm'}
        scroll={'paper'}
      >
        <DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <p>Histórico do trabalho <strong>{statusItem?.title}</strong>:</p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
          {
                  workStatusInfo.map((item, index) => (
                    <S.StatusList key={index}>
                      <li><strong>{item.status.label}</strong></li>
                      <li>{item.aditionalInfo}</li>
                      <li>Modificado em {dayjs(item.createdAt).format('DD/MM/YYYY - HH:mm')}</li>
                    </S.StatusList>
                  ))
                }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectedStatusModal} autoFocus>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        <S.RowContainer className='sp-bt top'>
          <S.SelectsContainer>
            <S.ColumnContainer width={28}>
              <TextField className='select' fullWidth size='small' select id='status' name='status' value={shownStatus} onChange={handleSelectStatus} label='Status do Trabalho'>
                <MenuItem value={shownStatus} style={{ display: 'none' }} disabled>{shownStatus}</MenuItem>
                {
                  workStatus.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                  ))
                }
              </TextField>
              <TextField className='select' fullWidth size='small' select id='board' name='board' value={shownBoard} onChange={handleSelectBoard} label='Diretoria'>
                <MenuItem value={shownBoard} style={{ display: 'none' }} disabled>{shownBoard}</MenuItem>
                {
                  boards.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                  ))
                }
              </TextField>
              <TextField className='select' fullWidth size='small' select id='management' name='management' value={shownManagement} disabled={managements.length === 0} onChange={handleSelectManagement} label='Gerência'>
                <MenuItem value={shownManagement} style={{ display: 'none' }} disabled>{shownManagement}</MenuItem>
                {
                  managements.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                  ))
                }
              </TextField>
            </S.ColumnContainer>
            <S.ColumnContainer width={28}>
              <TextField className='select' fullWidth size='small' select id='evaluators' name='evaluators' value={shownFilterEvaluator} disabled={evaluators.length === 0} onChange={handleSelectFilterEvaluator} label='Filtrar por Avaliador'>
                <MenuItem value={shownFilterEvaluator} style={{ display: 'none' }} disabled>{shownFilterEvaluator}</MenuItem>
                {
                  sortedEvaluators.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                  ))
                }
              </TextField>
              <TextField className='select' fullWidth size='small' select id='category' name='category' value={shownCategory} onChange={handleSelectCategory} label='Categoria'>
                <MenuItem value={shownCategory} style={{ display: 'none' }} disabled>{shownCategory}</MenuItem>
                {
                  categories.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                  ))
                }
              </TextField>
              <TextField className='select' fullWidth size='small' select id='modelTool' name='modelTool' value={shownModel} disabled={models.length === 0} onChange={handleSelectModel} label='Modelo / Ferramenta'>
                <MenuItem value={shownModel} style={{ display: 'none' }} disabled>{shownModel}</MenuItem>
                {
                  models.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                  ))
                }
              </TextField>
            </S.ColumnContainer>
            <S.ColumnContainer width={32} className='searchContainer'>
              <TextField id="search" type="search" size='small' label="Pesquisar por palavra-chave" value={search} onChange={handleSearch} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={onClickSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              />
            </S.ColumnContainer>
          </S.SelectsContainer>
        </S.RowContainer>
        <S.RowContainer className='sp-bt filters'>
          <S.RowContainer className='search'>
            <p>Filtros aplicados: <span>{shownBoard} {shownCategory} {shownManagement} {shownModel} {shownStatus} {shownFilterEvaluator} {noEvaluators} {shownSearch}</span></p>
          </S.RowContainer>
          <Button variant='outlined' onClick={() => { handleNoFilters() }}>Limpar Filtros</Button>
          <Button variant='outlined' onClick={handleNoEvaluators}>Ver trabalhos sem avaliador</Button>
        </S.RowContainer>
        {/* <S.RowContainer>
          <S.SelectEvaluatorContainer>
            <p>Distribuir trabalhos para: </p>
            <TextField className='select input' size='small' select id='evaluators' name='evaluators' value={shownEvaluator} disabled={evaluators.length === 0} onChange={handleSelectEvaluator} label='Escolher Avaliador'>
              <MenuItem value={shownEvaluator} style={{ display: 'none' }} disabled>{shownEvaluator}</MenuItem>
              {
                sortedEvaluators.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                ))
              }
            </TextField>
          </S.SelectEvaluatorContainer>
        </S.RowContainer> */}
        <S.RowContainer>
          <S.SelectEvaluatorContainer>
            <p>Distribuir trabalhos para: </p>
            <Autocomplete
              {...defaultProps}
              key={clearEvaluator}
              disablePortal
              id="evaluators"
              inputValue={shownEvaluator}
              onInputChange={(event, newInputValue) => {
                setShownEvaluator(newInputValue)
              }}
              onChange={handleAutocompleteChange}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: false
                    },
                    {
                      name: 'preventOverflow',
                      enabled: false
                    }
                  ]
                }
              }}
              sx={{ width: '70%' }}
              renderInput={(params) => <TextField {...params} className='select input' size='small' id='evaluators' name='evaluators' disabled={evaluators.length === 0} label='Escolher Avaliador' />}
            />
          </S.SelectEvaluatorContainer>
          <Button variant='outlined' disabled={selectedItems.length < 1} onClick={() => { void handleWorkReturn() }}>Retornar para o Banco</Button>
        </S.RowContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <>
                {
                  (works.length > 0 && works !== undefined && works !== null)
                    ? (
                      <>
                        <Paper>
                          <TableContainer>
                            <Table sx={{ minWidth: 1000 }}>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell />
                                  <StyledTableCell><Button onClick={() => { sortTable('code') }}>Código</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('title') }}>Título</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('board') }}>Diretoria</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('category') }}>Categoria</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('model') }}>Modelo / Ferramenta</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('createdAt') }}>Data / Hora Submissão</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('status') }}>Status</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={handleSort}>Avaliador</Button></StyledTableCell>
                                  <StyledTableCell><Checkbox checked={selectAll} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSelectAll(e) }} sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: theme.palette.primary.main
                                    }
                                  }} />
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  works.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell><Button onClick={async () => { await handleOpen(item) }}><LibraryBooksOutlinedIcon /></Button><Button className='button' onClick={() => { willDelete(item) }}><DeleteIcon className='icon button' /></Button></StyledTableCell>
                                      <StyledTableCell>{item.code}</StyledTableCell>
                                      <StyledTableCell>{item.title}</StyledTableCell>
                                      <StyledTableCell>{item.board.label}</StyledTableCell>
                                      <StyledTableCell>{item.category.label}</StyledTableCell>
                                      <StyledTableCell>{item.model.label}</StyledTableCell>
                                      <StyledTableCell>{dayjs(item.createdAt).format('DD/MM/YYYY - HH:mm')}</StyledTableCell>
                                      <StyledTableCell><Button style={item.status.id === 3 ? { color: 'green' } : item.status.id === 4 ? { color: 'orange' } : item.status.id === 5 ? { color: 'red' } : item.status.id === 6 ? { color: theme.palette.primary.main } : item.status.id === 1 ? { color: 'black' } : { color: 'darkgray' }} onClick={() => { handleChangeStatus(item) }}>{item.status.label}</Button></StyledTableCell>
                                      <StyledTableCell>{item.evaluator !== null ? item.evaluator.name : '-'}</StyledTableCell>
                                      <StyledTableCell><Checkbox checked={selectedItems.includes(item.id)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSelectWork(e, item.id) }} sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                          color: theme.palette.primary.main
                                        }
                                      }} /></StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[20, 50, 100]}
                            component="div"
                            count={works.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={'Trabalhos por página:'}
                            labelDisplayedRows={function defaultLabelDisplayedRows ({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}` }}
                          />
                          <Button sx={{ marginBottom: 1, marginLeft: 1 }} variant='outlined' onClick={handleWorkDistribution}>Salvar Configurações</Button>
                        </Paper>
                        {/* {
                          (showButton && notificationEvaluators.length > 0) && (
                            <>
                              {
                                notificationEvaluators.map((item, index) => (
                                  <p key={index}>{item.name}<Button className='button' onClick={() => { deleteNoficationEvaluator(index) }}>x</Button></p>
                                ))
                              }
                              <S.RowContainer className='buttonContainer'>
                                <Button onClick={notifyEvaluator} variant='outlined'>Enviar Notificação ao{notificationEvaluators.length > 1 ? 's' : ''} Avaliador{notificationEvaluators.length > 1 ? 'es' : ''}</Button>
                              </S.RowContainer>
                            </>
                          )
                        } */}
                        {
                          showButton && (
                            <>
                              <p>Avaliador selecionado: <strong>{shownEvaluatorForNotification}</strong></p>
                              <S.RowContainer className='buttonContainer'>
                                <Button onClick={notifyEvaluator} variant='outlined'>Enviar Notificação ao avaliador</Button>
                              </S.RowContainer>
                            </>
                          )
                        }
                      </>

                    )
                    : (
                      <h4>Não foram encontrados trabalhos submetidos neste evento</h4>
                    )
                }
              </>
            )
        }
      </S.ScreenContainer>
    </S.Container>
  )
}
