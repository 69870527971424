// Arquivo criado: 13/07/2023 às 18:33
import React from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { backEnd } from '../../utils/backend.util'
import { type IBoard } from '../../interfaces/IBoard'
import { type IManagement } from '../../interfaces/IManagement'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { setOpenBackDropLoading } from '../../redux/actions/backDropLoading.action'
import { type RootState } from '../../redux/store'
import { setLoginUser, setUser as setUserDispatch } from '../../redux/actions/user.action'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

export const Profile = (): JSX.Element => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userId = useSelector((state: RootState) => state.user.perfil?.id)
  const userEmail = useSelector((state: RootState) => state.user.perfil?.email)

  const [loading, setLoading] = React.useState(false)
  const [boards, setBoards] = React.useState<IBoard[]>([])
  const [managements, setManagements] = React.useState<IManagement[]>([])
  const [fullName, setFullName] = React.useState('')
  const [drt, setDrt] = React.useState<string | null>(null)
  const [board, setBoard] = React.useState('')
  const [shownBoard, setShownBoard] = React.useState('')
  const [management, setManagement] = React.useState('')
  const [shownManagement, setShownManagement] = React.useState('')
  const [email, setEmail] = React.useState(userEmail ?? '')
  const [cpf, setCpf] = React.useState('')
  const [shownCpf, setShownCpf] = React.useState('')
  const [telephone, setTelephone] = React.useState<string | null>(null)
  const [shownTelephone, setShownTelephone] = React.useState('')
  const [cellphone, setCellphone] = React.useState('')
  const [shownCellphone, setShownCellphone] = React.useState('')
  const [companyPosition, setCompanyPosition] = React.useState('')
  const [visible, setVisible] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [editable, setEditable] = React.useState(false)
  const [changed, setChanged] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'Submissão de trabalhos - Dados de Inscrição'

      const response = await backEnd('GET', 'boards')

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }

      setBoards(response.data)
      setManagements([])
    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getUserData = async (): Promise<void> => {
      if (userId) {
        setLoading(true)
        const response = await backEnd('GET', `users/${userId}`)
        setLoading(false)
        if (!response.ok) {
          dispatch(setOpenGlobalMessageAction({
            message: response.msg
          }))
          return
        }
        setEditable(true)
        setDisabled(true)

        setFullName(response.data.fullName)
        if (response.data.drt !== null) {
          setDrt(response.data.drt)
        }
        setBoard(response.data.board.id)
        setShownBoard(response.data.board.label)
        setManagement(response.data.management.id)
        setShownManagement(response.data.management.label)
        setCpf(response.data.cpf)
        setShownCpf(cpfMask(response.data.cpf))
        if (response.data.telephone !== null) {
          setTelephone(response.data.telephone)
        }
        setShownTelephone(phoneMask(response.data.telephone))
        setCellphone(response.data.cellphone)
        setShownCellphone(phoneMask(response.data.cellphone))
        setCompanyPosition(response.data.office)
      }
    }
    void getUserData()
  }, [userId, dispatch])

  const handleName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFullName(event.target.value)
    if (editable) {
      setChanged(true)
    }
  }

  /* const handleDRT = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDrt(event.target.value)
    if (editable) {
      setChanged(true)
    }
  } */

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
  }

  const handleCpfChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const _cpf = event.target.value
    const noMaskCPF = _cpf.replace(/\D+/g, '')
    setCpf(noMaskCPF)
    const input = event.target
    input.value = cpfMask(input.value)
    if (editable) {
      setChanged(true)
    }
  }

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const _telephone = event.target.value
    const noMaskTelephone = _telephone.replace(/\D+/g, '')
    setTelephone(noMaskTelephone)
    const input = event.target
    input.value = phoneMask(input.value)
    if (input.value === '') {
      setTelephone(null)
    }
    if (editable) {
      setChanged(true)
    }
  }

  const handleCellPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const _cellphone = event.target.value
    const noMaskCellphone = _cellphone.replace(/\D+/g, '')
    setCellphone(noMaskCellphone)
    const input = event.target
    input.value = phoneMask(input.value)
    if (editable) {
      setChanged(true)
    }
  }

  const phoneMask = (value: string): string => {
    if (!value) return ''
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '($1) $2')
    value = value.replace(/(\d)(\d{4})$/, '$1-$2')
    return value
  }

  const cpfMask = (value: string): string => {
    if (!value) return ''
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d)(\d{2})$/, '$1-$2')
    return value
  }

  const handlePosition = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCompanyPosition(event.target.value)
    if (editable) {
      setChanged(true)
    }
  }

  const handleClick = (): void => {
    navigate('/submissao')
  }

  const handleSelectBoard = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const board = boards.find(item => item.id === id)
    setBoard(e.target.value)

    if (!board) return

    const response = await backEnd('GET', `managements/board/${board.id}`)
    setShownBoard(board.label)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }
    setShownManagement('Gerência')
    setManagements(response.data)
    if (editable) {
      setChanged(true)
    }
  }

  const handleSelectManagement = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const management = managements.find(item => item.id === id)
    setManagement(e.target.value)
    if (!management) return
    setShownManagement(management.label)
    if (editable) {
      setChanged(true)
    }
  }

  const handleForm = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
  }

  const handleCancel = (): void => {
    if (changed) {
      const getUserData = async (): Promise<void> => {
        if (userId) {
          setLoading(true)
          const response = await backEnd('GET', `users/${userId}`)
          setLoading(false)
          if (!response.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: response.msg
            }))
            return
          }

          setFullName(response.data.fullName)
          /* if (response.data.drt !== null) {
            setDrt(response.data.drt)
          } */
          setBoard(response.data.board.id)
          setShownBoard(response.data.board.label)
          setManagement(response.data.management.id)
          setShownManagement(response.data.management.label)
          setCpf(response.data.cpf)
          setShownCpf(cpfMask(response.data.cpf))
          if (response.data.telephone !== null) {
            setTelephone(response.data.telephone)
          }
          setShownTelephone(phoneMask(response.data.telephone))
          setCellphone(response.data.cellphone)
          setShownCellphone(phoneMask(response.data.cellphone))
          setCompanyPosition(response.data.office)
        }
      }
      void getUserData()
    }
    setDisabled(true)
    setChanged(false)
  }

  const handleSave = async (): Promise<void> => {

    if (!fullName) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o seu nome completo para continuar',
        type: 'error'
      }))
      return
    }

    if (fullName.length < 3) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome deve conter no mínimo 3 caracteres',
        type: 'error'
      }))

      return
    }

    if (!fullName.match(/^[a-zA-ZÀ-ú ]+$/)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome deve conter apenas letras',
        type: 'error'
      }))

      return
    }

    /* if (!drt) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o DRT para continuar',
        type: 'error'
      }))
      return
    }

    if (drt.length > 20) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O DRT deve conter no máximo 20 caracteres',
        type: 'error'
      }))

      return
    } */

    if (!board) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione a Diretoria para continuar',
        type: 'error'
      }))
      return
    }

    if (!management) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione a Gerência para continuar',
        type: 'error'
      }))
      return
    }

    if (!cpf) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o seu CPF para continuar',
        type: 'error'
      }))
      return
    }

    if (cpf.length !== 11) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O CPF deve conter 11 dígitos',
        type: 'error'
      }))
      return
    }

    if (!cpf.match(/^[0-9]+$/)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O CPF deve conter apenas números',
        type: 'error'
      }))

      return
    }

    if (telephone && telephone.length !== 10) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O telefone deve conter 2 dígitos para o DDD e mais 8 dígitos',
        type: 'error'
      }))

      return
    }

    if (!cellphone) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o númeor do seu celular para continuar',
        type: 'error'
      }))

      return
    }

    if (cellphone.length !== 11) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O número do celular deve conter 2 dígitos para o DDD e mais 9 dígitos',
        type: 'error'
      }))
      return
    }

    if (!companyPosition) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o seu cargo para continuar',
        type: 'error'
      }))
      return
    }

    if (companyPosition.length < 2) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O cargo deve conter no mínimo 2 caracteres',
        type: 'error'
      }))

      return
    }

    if (!companyPosition.match(/^[\p{L}0-9 ]+$/u)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O cargo deve conter apenas letras e números',
        type: 'error'
      }))

      return
    }

    const data = {
      email,
      fullName,
      cpf,
      telephone,
      cellphone,
      drt,
      office: companyPosition,
      boardId: +board,
      managementId: +management
    }

    if (!editable) {

      dispatch(setOpenBackDropLoading(true))
      const response = await backEnd('POST', 'users', data)
      dispatch(setOpenBackDropLoading(false))

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          type: 'error'
        }))
        return
      }

      const { token, ...userData } = response.data

      if (!token || !userData.fullName || !userData.email || !userData.id) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Ocorreu um erro inesperado. Tente novamente mais tarde. Se o erro persistir, entre em contato com o suporte.',
          type: 'error',
          modal: { title: 'Erro inesperado' }
        }))
        return
      }

      dispatch(setOpenGlobalMessageAction({
        message: 'Inscrição realizada com sucesso. Para iniciar clique no botão "Continuar para Submissão de Trabalhos".',
        type: 'success',
        modal: {
          title: 'Inscrição realizada com sucesso'
        }
      }))

      dispatch(setLoginUser({
        isLogged: true,
        token
      }))

      dispatch(setUserDispatch(userData))

      setDisabled(true)
      setVisible(true)
      setEditable(true)

      return
    }

    if (!userId) return

    dispatch(setOpenBackDropLoading(true))
    const response = await backEnd('PUT', `users/${userId}`, data)
    dispatch(setOpenBackDropLoading(false))

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Seus dados de inscrição foram alterados.',
      type: 'success',
      modal: {
        title: 'Dados alterados com sucesso'
      }
    }))

    setDisabled(true)
    setChanged(false)

  }

  const canEdit = (): void => {
    setDisabled(false)
  }

  const handleLogout = (): void => {
    dispatch(setLoginUser({
      isLogged: false,
      token: null
    }))

    dispatch(setUserDispatch(null))
  }

  return (
    <S.Container>
      <S.FormContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <S.Form onSubmit={handleForm}>
                <S.RowContainer>
                  <S.TitleContainer>
                    <h2>Dados da Inscrição</h2>
                  </S.TitleContainer>
                </S.RowContainer>
                <S.RowContainer justifycontent='flex-start'>
                  <S.ColumnContainer>
                    <TextField required size='small' id='fullName' name='fullName' defaultValue={fullName} disabled={disabled} label='Nome Completo' onChange={handleName}/>
                    <TextField required size='small' select id='board' name='board' value={shownBoard} onChange={handleSelectBoard} label='Diretoria' disabled={disabled}>
                      <MenuItem value={shownBoard} style={{ display: 'none' }} disabled>{shownBoard}</MenuItem>
                      {
                        boards.map((item, index) => (
                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                        ))
                      }
                    </TextField>
                    <TextField required size='small' select id='management' name='management' value={shownManagement} disabled={managements.length === 0 || disabled} onChange={handleSelectManagement} label='Gerência'>
                      <MenuItem value={shownManagement} style={{ display: 'none' }} disabled>{shownManagement}</MenuItem>
                      {
                        managements.map((item, index) => (
                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                        ))
                      }
                    </TextField>
                    <TextField required size='small' id='office' name='office' defaultValue={companyPosition} disabled={disabled} label='Cargo' onChange={handlePosition}/>
                  </S.ColumnContainer>
                  <S.ColumnContainer>
                    <TextField required size='small' id='email' name='email' disabled defaultValue={email} label='E-mail' onChange={handleEmail}/>
                    <TextField required size='small' id='cpf' name='cpf' defaultValue={shownCpf} disabled={disabled} label='CPF' inputProps={{ maxLength: 14 }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleCpfChange(e) }}/>
                    <TextField size='small' id='telephone' name='telephone' defaultValue={shownTelephone} disabled={disabled} inputProps={{ maxLength: 14 }} type='tel' label='Telefone Fixo' onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handlePhoneChange(e) }} />
                    <TextField required size='small' id='cellphone' name='cellphone' defaultValue={shownCellphone} disabled={disabled} label='Celular' inputProps={{ maxLength: 15 }} type='tel' onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleCellPhoneChange(e) }}/>
                  </S.ColumnContainer>
                </S.RowContainer>
                <S.ButtonsContainer>
                  {
                    editable
                      ? (
                        <Button onClick={changed ? handleSave : canEdit} variant='outlined'>{changed ? 'Salvar Alterações' : 'Editar'}</Button>
                      )
                      : (
                        <Button onClick={handleSave} variant='outlined'>Salvar</Button>
                      )
                  }
                  {
                    (!disabled && changed) && (
                      <Button onClick={handleCancel} variant='outlined'>Cancelar</Button>
                    )
                  }
                  {
                    visible && (
                      <Button variant='outlined' onClick={handleClick}>Continuar para submissão de trabalhos</Button>
                    )
                  }
                  {
                    (!editable && !disabled) && (
                      <Button variant='outlined' onClick={handleLogout}>Sair</Button>
                    )
                  }
                </S.ButtonsContainer>
              </S.Form>
            )
        }
      </S.FormContainer>
    </S.Container>
  )
}
