import styled from 'styled-components'
import { theme } from '../../config/theme'

interface IColumn {
  width?: number
}

interface IRow {
  justifycontent?: string
  alignitems?: string
}

interface IContainer {
  $isdragactive: boolean
}

const xs = 415
const sm = 600
const md = 1000 // mudei de 878 p 1000 pq os select estavam se sobrepondo
const lg = 1090

export const Container = styled.div<IContainer>`
  color: #ccc;
  border: ${({ $isdragactive }) => ($isdragactive ? `2px dashed ${theme.palette.primary.main}` : 'none')};
  margin: 0;
  padding: 10px;
  align-items: center;
  .rowSpBetween {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rowFxStart {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .noMarginTop {
    margin-top: 0;
  }
  .noMarginBottom {
    margin-bottom: 0;
  }
  .noBreak {
    @media (max-width: ${md}px) {
      flex-direction: row;
  }
  }
  .finalSubmission {
    width: 60%;
    padding: 10px;
    p {
      text-align: center;
      //margin-bottom: 15px;
    }
    button {
      margin-top: 35px;
    }
  }
  label {
  color: #444;
  }
  .MuiTextField-root {
    width: 98%;
    margin-bottom: 15px;
  }
  .MuiCheckbox-root, .css-d712wd-MuiButtonBase-root-MuiCheckbox-root {
    color: #3b81f7;
  }
`
// Header
export const HeaderContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
  margin-bottom: 10px;
  p {
    color: #000;
    text-align: left;
  }
  button {
    margin: 5px 0;
    cursor: pointer;
    &:focus{
      p {
        font-weight: 700;
      }
    }
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
    justify-content: center;
    button {
      margin-top: 10px;
    }
  }
`

export const ButtonHeaderContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  align-items: center;
  @media (max-width: ${md}px) {
    width: 100%;
    margin: 0 auto 20px auto;
    justify-content: center;
  }
  button {
    background-color: #3b81f7;
    color: #fff;
    border: 1px solid #3b81f7;
    &:hover {
      background-color: #3b81f7dd;
    }
  }  
`

export const TitleContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //margin-left: 10px;
  //margin-bottom: 20px;
  @media (max-width: ${md}px) {
    width: 100%;
    margin: 10px auto;
  }
  h2{
    margin: 0;
    color: rgba(94, 53, 177, 0.7);
  }

`
// Progress bar
export const RowContainerForSteps = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: ${md}px) {
    button {
      font-size: 10px;
    }
    width: 85%;
    justify-content: center;
  }
  @media (max-width: ${sm}px) {
    width: 90%;
  }
`

export const StepperContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  align-self: start;
  @media (max-width: ${md}px) {
    width: 100%;
    margin-bottom: 20px;
    align-self: center;
    align-items: center;
  }
`

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Steps = styled.button`
  p {
    color: #000;
    margin-right: 3px;
    font-size: 1rem;
    /* @media (max-width: ${sm}px) {
      font-size: 8px;
    }
    @media (max-width: ${xs}px) {
      font-size: 7px;
    } */
  }
  .icon {
    font-size: 1.5rem;
    /* @media (max-width: ${sm}px) {
      font-size: 16px;
    }
    @media (max-width: ${xs}px) {
      font-size: 7px;
    } */
  }
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
  background-color: #fff;
  border-width: 0;
  cursor: pointer;
  @media (min-width: ${md}px) {
    padding-left: 0;
    justify-content: flex-start;
  }
`

export const Stepper = styled.div`
  width: 80%;
  aspect-ratio: 50/1;
  border-radius: 10px;
  background-color: #3b81f799 ;
`

export const ShadowStepper = styled.div`
  width: 0;
  height: 100%;
  border-radius: 10px;
  background-color: #3b81f7;
  align-self: flex-start;
`

// Commom body
export const FormContainer = styled.div`
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 .step5Container {
  align-items: flex-start;
  @media (max-width: ${md}px) {
    align-items: center;
  }
 }
`

export const Form = styled.form`
  width: 90%;
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 20px 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    color: #000;
    text-align: justify;
    font-size: 13px;
  }
  .step1Inputs {
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width: ${md}px) {
      width: 100%;
    }
  }
  .first {
    @media (min-width: ${md}px) {
      margin-top: 0;
    }
  }
  .step3Paragrafy {
    margin-top: 0;
    align-self: left;
  }
  .css-d712wd-MuiButtonBase-root-MuiCheckbox-root {
    color: #3b81f7;
  }
  
`
export const RowContainer = styled.div<IRow>`
  width: 100%;
  display: flex;
  align-items: ${({ alignitems }) => alignitems ?? 'center'};
  justify-content: ${({ justifycontent }) => justifycontent ?? 'space-between'};
  h3, h4 {
    color: #000;
    text-align: right;
  }
  button {
    margin: 5px 0;
    cursor: pointer;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
    button {
      margin-top: 10px;
    }
  }
  .authors {
    justify-content: flex-start;
    margin-left: 10px;
    @media (max-width: ${md}px) {
      margin-bottom: 20px;
      margin-left: 0;
      justify-content: space-evenly;
    }
    h3 {
      margin-bottom: 4px;
    }
    p {
    color: #000;
    text-align: justify;
    font-size: 13px;
    margin-bottom: 0;
    }
  .complement {
    margin-top: 0;
  }
  .MuiFormControlLabel-label {
    color: #000;
    font-size: 12px;
  }
  .MuiRadio-colorPrimary, .MuiCheckbox-colorPrimary {
    color: #3b81f7;
  }
  .MuiSvgIcon-root {
      font-size: 16px;
      margin-top: 0;
  }
  }
  .summaryLastColumn {
    justify-content: flex-start;
  }
  .summaryAdvise {
    margin-top: 0;
  }
  .summaryAdviseTitle {
    margin: 0;
    
    @media (max-width: ${md}px) {
     margin: 20px 0 0 0;
    }
  }
  
`
// steps 1 and 2
export const ButtonsContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  button {
    margin-right: 20px;
  }
  @media (max-width: ${lg}px) {
    button {
      font-size: 10px;
    }
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
    button {
      margin-top: 10px;
      margin-right: 0;
    }
  }

`

// step1
export const CustomTextArea = styled.textarea`
  width: 98.5%;
  aspect-ratio: 8/1;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 8px;
  margin: 7px 0;
  font-family: ${theme.typography.fontFamily};
  resize: none;
  outline: none;
  &:focus {
    border: 1px solid rgba(94, 53, 177, 0.9);
  }
  &:placeholder-shown {
   font-style: italic;
  }  
  @media (max-width: ${md}px) {
    width: 90%;
  }
`
export const RadioGroupContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 15px 0 0 0; 
  @media (max-width: ${md}px) {
    width: 90%;
    justify-content: center;
    margin: 0;
    align-self: center;
  }
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    width: 75%;
  }
  p {
    color: #000;
    text-align: justify;
    font-size: 13px;
    margin-bottom: 0;
  }
  .first {
    @media (min-width: ${md}px) {
      margin-top: 0;
    }
  }
  .MuiFormControlLabel-label {
    color: #000;
    font-size: 14px;
  }
  .MuiRadio-colorPrimary {
    color: #3b81f7;
  }
  .MuiSvgIcon-root {
      font-size: 16px;
      margin-top: 0;
  }
`

// step2
export const IncludeAuthorsBox = styled.div`
  width: 90%;
  //aspect-ratio: 2/1;
  background-color: #3b81f744;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 8px;
  p {
    margin: 0;
    text-align: center;
    font-style: italic;
  }  
`

export const SingleAuthor = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  @media (max-width: ${md}px) {
      justify-content: center;
    }
  .icon {
    color: #666;
    //margin-left: 4px;
    font-size: 17px;
    &:hover {
      color: #222;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13.5px;
  }
  button {
    max-width: 23px;
    max-height: 23px;
    min-width: 23px;
    min-height: 23px;
    margin: 0;
    padding: 7px;
  }
`
export const InputsContainer = styled.div` 
  width: 65%;
  margin: 5px 0;
  @media (max-width: ${sm}px) {
    width: 90%;
  }
  @media (min-width: ${md}px) and (max-width: ${lg}px) {
    margin: 10px 0;
  }
  button {
    width: 100%;
    height: 30px;
    //aspect-ratio: 10/1;
    border-radius: 4px;
    background-color: #3b81f7;
    color: #fff;
    border: 1px solid #3b81f7;
    margin: 5px 0;
    cursor: pointer;
    &:hover {
      background-color: #3b81f7dd;
    }
  }  
`

// step3
export const SelectFileBox = styled.div`
  width: 40%;
  background-color: #3b81f744;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  margin: 2px 0 30px 0;
  @media (max-width: ${md}px) {
    width: 45%;
  }
  div {
    align-items: center;
    p {
      margin: 0;
      text-align: center;
    }
  }
  input[type="file"] {
    display: none;
  }
  label {
    width: 70%;
    text-align: center;
    display: block;
    //max-height: 40px;
    //aspect-ratio: 10/1;
    padding: 7px 5px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #3b81f7;
    color: #fff;
    border: 1px solid #3b81f7;
    margin: 5px 0;
    font-size: 13px;
    &:hover {
      background-color: #3b81f7dd;
    }
  }
`
export const PDFBox = styled.div`
  width: 40%;
  background-color: #3b81f744;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px 0 30px 0;
  @media (max-width: ${md}px) {
    width: 45%;
  }
  div {
    align-items: center;
    p {
      margin: 0;
      text-align: center;
    }
  }
  img {
    margin-bottom: 15px;
  }

`
export const SelectFile = styled.button`
  width: 70%;
  max-height: 40px;
  aspect-ratio: 10/1;
  padding: 5px;
  background-color: #eee;
  border-radius: 4px;
  border: 1px solid #666;
  cursor: pointer;
  background-color: #3b81f7;
  color: #fff;
  border: 1px solid #3b81f7;
  margin: 5px 0;
  &:hover {
    background-color: #3b81f7dd;
  }
`

export const Step3DividerContainer = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #000;
  }
`

// steps 3 and 5
export const SimpleRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .checkboxLabel {
    font-size: 12px;
  }
`
export const StepsTextContainer = styled.div`
  width: 90%;
  //margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 15px;
  @media (max-width: ${md}px) {
    margin: 10px 0;
  }
`

// steps 4 and 6
export const InformationContainer = styled.div`
 width: 50%;
 aspect-ratio: 2/1;
 background-color: #3b81f744;
 border-radius: 8px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 margin: 30px 0;
 padding: 10px;
 h2 {
  color: #000;
  text-align: center;
 }
 button {
    cursor: pointer;
    background-color: #3b81f7;
    color: #fff;
    border: 1px solid #3b81f7;
    margin: 20px 0;
    &:hover {
      background-color: #3b81f7dd;
    }
  }
  p {
    color: #000;
    margin: 0 5px;
    text-align: justify;
  }
 @media (max-width: ${md}px) {
    width: 80%;
  }
`

// step5
export const SummaryContainer = styled.div`
  width: 100%;
  .title {
      margin-bottom: 5px;      
    }
  .summaryRow {
    justify-content: flex-start;
    p {
      margin: 0;
    }
    .secondItem {
    margin-left: 20px;
    }
  }
  .summaryTitle {
    margin-top: 0;
    font-size: 20px;
  }
  .summaryInLineItem {
    margin-right: 3px;
  } 
  .middleRow {
    margin-top: 20px;
    @media (max-width: ${md}px) {
      margin-top: 15px;
    }
  } 
  .lastRow {
    margin-top: 15px;
  } 
  .authorsStep5 {
    margin-top: 0;
    align-self: flex-start;
  }
  .blueBorder {
    border: 0 solid #3b81f799;
    border-bottom-width: 1px;
    padding-bottom: 1px;
  }
  .topic {
    font-size: 16px;
  }
  .author {
    font-size: 13px;
  }
  .summaryTagsContainer {
    @media (max-width: ${md}px) {
      margin-top: 15px;
    }
  }

`
export const TagsContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${md}px) {
    justify-content: center;
  }
  .summaryInLineItem {
    margin-right: 8px;
  }
`
export const ListAuthors = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0 0 0;
  border: 0 solid #3b81f799;
  border-bottom-width: 1px;
  padding-bottom: 10px;
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  aspect-ratio: 2/1;
`

export const PopulationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: auto;
  width: 100%;
  p {
    margin: 0 0 5px 0;
  }
`

export const DiversePopulationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin: 5px 0;
  width: 100%;
  p {
    font-style: italic;
    font-size: 0.7rem;
  }
`
