// Arquivo criado: 16/07/2023 às 15:17
import React, { useState } from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Navbar } from '../../components/Navbar'
import eventLogo from '../../assets/SELO.png'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginUser, setUser } from '../../redux/actions/user.action'
import { useNavigate } from 'react-router-dom'
import { type RootState } from '../../redux/store'
import dayjs from 'dayjs'
import googleIcon from '../../assets/googleIcon.png'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import * as CryptoJS from 'crypto-js'
import { googleConfig } from '../../config/google'
import { urlFile } from '../../utils/urlFile.util'
import bg006 from '../../assets/bg006.jpg'

export const Login = (): JSX.Element => {

  const event = useSelector((state: RootState) => state.event.data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openLogin, setOpenLogin] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [checked, setChecked] = useState(false)
  const [login, setLogin] = useState(true)
  const dataInLocalStorage = window.localStorage.getItem('userData')
  const obj = dataInLocalStorage !== null ? JSON.parse(dataInLocalStorage) : []
  const [loggedIn, setLoggedIn] = useState<string[]>(obj)
  const [fileUrl, setFileUrl] = React.useState<string>('')
  const [showDate, setShowDate] = React.useState(true)
  const [eventTitle, setEventTitle] = React.useState(false)
  const [fileImg, setFileImg] = React.useState<string>('')
  const [eventImg, setEventImg] = React.useState<string>('')

  /* React.useEffect(() => {
    document.title = 'Submissão de trabalhos - Login'
  }, []) */

  React.useEffect(() => {
    document.title = 'Submissão de trabalhos - Login'
    const getImg = async (): Promise<void> => {
      try {
        const img = await urlFile('addons/login-background')
        if (img) setFileImg(img)
      } catch (e) {
        console.log((e as Error).message)
        setFileImg(bg006)
      }
    }
    void getImg()
  }, [])

  React.useEffect(() => {
    const getImgLogo = async (): Promise<void> => {
      try {
        const imgLogo = await urlFile('addons/logo')
        if (imgLogo) setEventImg(imgLogo)
      } catch (e) {
        console.log((e as Error).message)
        setEventImg(eventLogo)
      }
    }
    void getImgLogo()
  }, [])

  React.useEffect(() => {
    const getUrl = async (): Promise<void> => {
      try {
        const url = await urlFile('addons/policies')
        if (!url) return
        if (url) setFileUrl(url)
      } catch (e) {
        console.log((e as Error).message)
      }
    }
    void getUrl()
  }, [])

  React.useEffect(() => {

    if (event) {
      const lastDay = new Date(event?.finalDate)

      const checkDates = (): void => {
        const today = new Date()
        lastDay < today ? setShowDate(false) : setShowDate(true)
        lastDay < today ? setEventTitle(true) : setEventTitle(false)
      }

      checkDates()

      const compareDates = setInterval(checkDates, 60000)
      if (!showDate) {
        clearInterval(compareDates)
      }

    }

  }, [event, showDate])

  const handleModalOpen = (): void => {
    setChecked(false)
    if (email === '') {
      setLogin(true)
      dispatch(setOpenGlobalMessageAction({
        message: 'O e-mail deve ser informado',
        type: 'error'
      }))

      return
    }

    if (!(/^[a-z0-9._+-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/gi.test(email))) {
      setLogin(true)
      dispatch(setOpenGlobalMessageAction({
        message: 'E-mail inválido',
        type: 'error'
      }))

      return
    }

    if (password === '') {
      setLogin(true)
      dispatch(setOpenGlobalMessageAction({
        message: 'A senha deve ser informada',
        type: 'error'
      }))

      return
    }

    let count = 0
    loggedIn.map((item) => {
      if (email === item) {
        count += 1
      }
      return count
    })

    if (count === 0) {
      setOpenLogin(false)
      setOpen(true)
    } else {
      void handleClose()
    }

  }

  const handleLoginModalOpen = (): void => {
    // setOpen(false)
    setEmail('')
    setPassword('')
    setOpenLogin(true)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked)
  }

  const handleClose = async (): Promise<void> => {

    let count = 0
    loggedIn.map((item) => {
      if (email === item) {
        count += 1
      }
      return count
    })

    if (count === 0) {
      localStorage.setItem('userData', JSON.stringify([...loggedIn, email]))
      setLoggedIn(
        [
          ...loggedIn,
          email
        ]
      )
    }

    const newHashedPassword = CryptoJS.AES.encrypt(password, 'i642w0NXMCLt6MAbdxhjRNW7q77oqlbm').toString()
    const response = await backEnd('POST', 'users/sign-in', { email, password: newHashedPassword })

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        modal: {
          title: 'Não foi possível fazer o login'
        },
        // message: response.msg,
        message: 'Verifique suas credenciais e tente novamente',
        type: 'error'
      }))
      setOpen(false)
      setEmail('')
      setPassword('')
      return
    }

    setOpen(false)

    const saveInfos = (): void => {
      const { token, ...user } = response.data

      dispatch(setLoginUser({
        isLogged: true,
        token
      }))

      dispatch(setUser(user))

      if (response.data.needInfos) {
        navigate('/perfil')

        return
      }

      navigate('/')

    }

    if (response.data.needInfos) {

      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        modal: {
          title: 'Tudo certo!',
          onConfirm: {
            /* text: response.data.needInfos ? 'Preencher informações' : 'Ok', */
            text: 'Preencher informações',
            action: saveInfos
          },
          dimissable: false
        },
        type: 'success'
      }))
      return
    }
    saveInfos()

  }

  const handleModalClose = (): void => {
    setOpen(false)
  }

  const handleLoginModalClose = (): void => {
    setOpenLogin(false)
  }

  const handleClick = (): void => {
    handleLoginModalOpen()
  }

  /* const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
  } */

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
    if (password !== '') {
      setLogin(false)
    }
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value)
    if (email !== '') {
      setLogin(false)
    }
  }

  const googleLogin = (): void => {
    // localStorage.setItem('evaluator', '1')
    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${googleConfig.clientId}&redirect_uri=${googleConfig.redirectUri}&response_type=${googleConfig.responseType}&scope=${googleConfig.scope}`
  }

  return (
    <S.Container style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 9, 0.98), rgba(0, 0, 9, 0.40), rgba(0, 0, 9, 0.7), rgba(0, 0, 9, 0.9)), url(${fileImg})` }}>
      <Navbar />
      <S.ScreenContainer>
        <S.RowContainer>
          <S.LogoContainer>
            <img src={eventImg}/>
          </S.LogoContainer>
          <S.TitleContainer>
            {
              eventTitle
                ? (
                  <h1>Evento online</h1>
                )
                : (
                  <h1>Submissão de trabalhos</h1>
                )
            }
          </S.TitleContainer>
        </S.RowContainer>
        <S.FormContainer>
          <S.TextContainer>
            <Button className='einsteinButton' onClick={handleClick} variant='outlined'>Entrar com uma conta Einstein</Button>
            <p className='or'>ou</p>
            <Button onClick={googleLogin} className='googleButton' variant='outlined'><img src={googleIcon} width={20} style={{ marginRight: 10 }}/>Entrar com uma conta Google</Button>
            <S.RowContainer className='buttonsBox'>
              {/* <Button className='googleButton' variant='outlined'><img src={googleIcon} width={20} style={{ marginRight: 10 }}/>Entrar com uma conta Google</Button> */}
              {/* <Button className='facebookButton' variant='outlined'><img src={facebookIcon} width={20} style={{ marginRight: 10 }}/>Entrar com uma conta Facebook</Button> */}
            </S.RowContainer>
            {
              (event && showDate) && (
                <p>Inscrições abertas de {dayjs(event.initialDate).format('DD/MM')} a {dayjs(event.finalDate).format('DD/MM/YYYY')}</p>
              )
            }
          </S.TextContainer>
        </S.FormContainer>
        <Dialog open={open} onClose={handleModalClose}>
          <DialogTitle>Leia os termos para se inscrever</DialogTitle>
          <DialogContent>
            <Checkbox
              sx={{
                color: '#999'
              }}
              checked={checked}
              onChange={handleChange}
              id='terms'
            />
            <label htmlFor='terms'>Eu concordo com a <S.LinkStyle href={fileUrl} target="_blank" rel="noopener noreferrer">Política Institucional de Segurança da Informação</S.LinkStyle></label>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={!checked}>Continuar para a inscrição</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openLogin} onClose={handleLoginModalClose}>
          <DialogTitle>Entre com suas credenciais Einstein:</DialogTitle>
          <DialogContent>
            <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
              <InputLabel sx={{ color: '#555' }} htmlFor="email">E-mail</InputLabel>
              <OutlinedInput id="email" label="E-mail" value={email} onChange={handleEmail} />
            </FormControl>
          </DialogContent>
          <DialogContent>
            <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
              <InputLabel sx={{ color: '#555' }} htmlFor="password">Senha</InputLabel>
              <OutlinedInput
                id="password"
                // type={showPassword ? 'text' : 'password'}
                type={'password'}
                value={password}
                onChange={handlePassword}
                /* endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                } */
                label="Senha"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalOpen} disabled={login}>Acessar</Button>
          </DialogActions>
        </Dialog>
      </S.ScreenContainer>
    </S.Container>
  )
}
