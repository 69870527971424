import { styled } from 'styled-components'

export const Container = styled.div`
  margin: 0;
`
export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 12/1;
`
