import styled from 'styled-components'

interface IColumn {
  width?: number
}

interface IRow {
  justifycontent?: string
  alignitems?: string
}

const sm = 600
const md = 1000 // mudei de 878 p 1000 pq os select estavam se sobrepondo
const lg = 1090

export const Container = styled.div`
  color: #ccc;
  margin: 0;
  padding: 10px;
  align-items: center;
  label {
  color: #444;
  }
  .MuiTextField-root {
    width: 98%;
    margin-bottom: 15px;
  }
  .MuiCheckbox-root {
    color: #3b81f7;
  }
`
// Header
export const HeaderContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
  margin-bottom: 10px;
  p {
    color: #000;
    text-align: left;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const TitleContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //margin-left: 10px;
  //margin-bottom: 20px;
  @media (max-width: ${md}px) {
    width: 100%;
    margin: 10px auto;
  }
  h2{
    margin: 0;
    color: rgba(94, 53, 177, 0.7);
  }

`

// Commom body
export const FormContainer = styled.div`
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
`

export const Form = styled.form`
  width: 90%;
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 20px 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    color: #000;
    text-align: justify;
    font-size: 13px;
  }
  
`
export const RowContainer = styled.div<IRow>`
  width: 100%;
  display: flex;
  align-items: ${({ alignitems }) => alignitems ?? 'center'};
  justify-content: ${({ justifycontent }) => justifycontent ?? 'space-between'};
  h3, h4 {
    color: #000;
    text-align: right;
  }
  button {
    margin: 5px 0;
    cursor: pointer;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
    button {
      margin-top: 10px;
    }
  }
  .authors {
    justify-content: flex-start;
    margin-left: 10px;
    @media (max-width: ${md}px) {
      margin-bottom: 20px;
      margin-left: 0;
      justify-content: space-evenly;
    }
    h3 {
      margin-bottom: 4px;
    }
    p {
    color: #000;
    text-align: justify;
    font-size: 13px;
    margin-bottom: 0;
    }
  }  
  
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;

`

export const IncludeAuthorsBox = styled.div`
  width: 90%;
  background-color: #3b81f744;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 8px;
  p {
    margin: 0;
    text-align: center;
    font-style: italic;
  }  
`

export const SingleAuthor = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  @media (max-width: ${md}px) {
      justify-content: center;
    }
  .icon {
    color: #666;
    //margin-left: 4px;
    font-size: 17px;
    &:hover {
      color: #222;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13.5px;
  }
  button {
    max-width: 23px;
    max-height: 23px;
    min-width: 23px;
    min-height: 23px;
    margin: 0;
    padding: 7px;
  }
`
export const InputsContainer = styled.div` 
  width: 65%;
  margin: 5px 0;
  @media (max-width: ${sm}px) {
    width: 90%;
  }
  @media (min-width: ${md}px) and (max-width: ${lg}px) {
    margin: 10px 0;
  }
  button {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    background-color: #3b81f7;
    color: #fff;
    border: 1px solid #3b81f7;
    margin: 5px 0;
    cursor: pointer;
    &:hover {
      background-color: #3b81f7dd;
    }
  }  
`

export const SelectFileBox = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  margin: 2px 0 30px 0;
  @media (max-width: ${md}px) {
    width: 45%;
  }
  div {
    align-items: center;
    p {
      margin: 0;
      text-align: center;
    }
  }
  input[type="file"] {
    display: none;
  }
  label {
    width: 57%;
    text-align: center;
    display: block;
    //max-height: 40px;
    //aspect-ratio: 10/1;
    padding: 7px 5px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #3b81f7;
    color: #fff;
    border: 1px solid #3b81f7;
    margin: 5px 0;
    font-size: 13px;
    &:hover {
      background-color: #3b81f7dd;
    }
  }
`

export const ListAuthors = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0 0 0;
  border: 0 solid #3b81f799;
  border-bottom-width: 1px;
  padding-bottom: 10px;
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  aspect-ratio: 2/1;
`
