// Arquivo criado: 24/10/2023 às 13:54
import React from 'react'
import * as S from './styles'
import { type INotification } from '../../interfaces/INotification'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import CircularProgress from '@mui/material/CircularProgress'
import { NotificationBox } from '../../components/NotificationBox'
import dayjs from 'dayjs'
import Button from '@mui/material/Button'

export const NotificationsEvaluator = (): JSX.Element => {

  const dispatch = useDispatch()
  const userId = useSelector((state: RootState) => state.user.perfil?.id)

  const [loading, setLoading] = React.useState(false)
  const [notifications, setNotifications] = React.useState<INotification[]>([])
  const [message, setMessage] = React.useState<string>('')
  const [openMessage, setOpenMessage] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'Avaliação de trabalhos - Notificações'

      if (!userId) return

      setLoading(true)
      const response = await backEnd('GET', `notifications/evaluator/${userId}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }

      const data: INotification[] = response.data

      setNotifications(data)

    }
    void getData()
  }, [dispatch, userId])

  const readMessageHTML = (): { __html: string | TrustedHTML } => {
    return { __html: `${message}` }
  }

  const setRead = async (id: number): Promise<void> => {
    const read = await backEnd('PUT', `notifications/${id}/read`)

    if (!read.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: read.msg,
        type: 'error'
      }))
    }
    if (!userId) return
    const response = await backEnd('GET', `notifications/evaluator/${userId}`)
    const data: INotification[] = response.data

    setNotifications(data)
  }

  const readMessage = (msg: string, id: number): void => {
    setMessage(msg)
    setOpenMessage(true)
    const _id = id
    const _message = notifications.find(item => item.id === _id)

    if (!_message) return

    if (_message.read) return

    void setRead(_id)
  }

  return (
    <S.Container>
      {
        loading
          ? (
            <S.LoadingContainer>
              <CircularProgress />
            </S.LoadingContainer>
          )
          : (
            <S.ScreenContainer>
              {
                openMessage
                  ? (
                    <S.ReadNotification>
                      <S.BorderColumn />
                      <S.MessageContainer style={{ alignSelf: 'center', margin: 20 }} dangerouslySetInnerHTML={readMessageHTML()} />
                      <S.BorderColumn>
                        <Button variant='outlined' onClick={() => { setOpenMessage(false) }}>Voltar</Button>
                      </S.BorderColumn>
                    </S.ReadNotification>
                  )
                  : (
                    <>
                      <h2>Notificações</h2>
                      <p className='information'>Você possui {notifications.length} notificaç{notifications.length !== 1 ? 'ões' : 'ão'}</p>
                      {
                        notifications.map((item, index) => (
                          <S.NotificationsContainer key={index}>
                            <NotificationBox isColumn={false} title={item.title} read={item.read} createdAt={dayjs(item.createdAt).format('DD/MM/YYYY')} action={() => { readMessage(item.message, item.id) }}/>
                          </S.NotificationsContainer>
                        ))
                      }
                    </>

                  )
              }
            </S.ScreenContainer>
          )
      }
    </S.Container>
  )
}
