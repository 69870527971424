import { type Action } from 'redux'

export const SET_OPEN_BACKDROP_LOADING = 'SET_OPEN_BACKDROP_LOADING'

export interface SetOpenBackDropLoading extends Action<typeof SET_OPEN_BACKDROP_LOADING> {
  payload: boolean
}

export const setOpenBackDropLoading = (payload: boolean): SetOpenBackDropLoading => ({
  type: SET_OPEN_BACKDROP_LOADING,
  payload
})
