import styled from 'styled-components'
import { theme } from '../../config/theme'
interface IColumn {
  width?: number
}

const md = 1090

export const Container = styled.div`
  color: #ccc;
  padding: 10px;
  margin: 0;
  h2 {
    margin: 0 0 10px 0;
  }
  h4 {
      color: #000;
      margin-top: 5px;
      margin-bottom: 0;
    }
    .emphasis {
      font-size: 16px;
      color: #000;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
    }
    .information {
      font-size: 15px;
      font-weight: normal;
      margin-top: 0;
    }
    .switchesBox {
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      @media (max-width: ${md}px) {
        flex-direction: column;
      }
    }
    .css-j204z7-MuiFormControlLabel-root {
      border: 1px solid ${theme.palette.primary.main};
      border-radius: 4px;
      width: 45%;
      margin: 10px auto 20px auto;
    }
    .eventInformation {
      align-self: flex-start;
      justify-content: flex-start;
      margin-top: 20px;
      /* @media (max-width: ${md}px) {
        flex-direction: column-reverse;
      } */
    }
    .pdfFile {
      width: 45%;
      display: flex;
      align-items: center;
      @media (max-width: ${md}px) {
        width: 20%;
      }
    }
    .pdfSize {
      margin-left: 5px;
    }
    .MuiTextField-root {
    width: 100%;
    margin: 0 0 5px 0;
    }
    .noMargin {
      margin: 0;
    }
    .noMarginBottom {
      margin-bottom: 0;
    }
`
export const ScreenContainer = styled.div`
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 padding: 0 15px;
 h2{
    color: rgba(94, 53, 177, 0.7);
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
`
export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
  }
  .marginTop {
    margin-top: 5px;
  }
`
export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p, h3, h5 {
    color: #000;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  label {
    color: #444;
  }
  .last {
    margin-top: 35px;
  }
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  .placeContainer {
    margin-top: 25px;
  }
`

export const FormGroupsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  label {
    color: #666;
    font-size: 13px;
    font-style: italic;
  }
  .MuiTextField-root {
    width: 100%;
    margin: 20px 0;
  }
  .groupTitle {
    justify-content: flex-start;
  }
  .groupLine {
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
  }
  .groupInputs {
    justify-content: flex-start;
    p {
      margin: 5px 0 0 0;
      font-style: italic;
    }
    .MuiTextField-root {
    width: 100%;
    margin: 4px 0;
  }

  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ImportsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 5px;
  @media (max-width: ${md}px) {
    width: 50%;
  }
  button {
    margin: 15px auto;
  }

`

export const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`

export const LinkStyle = styled.button`
  text-decoration: underline;
  color: ${theme.palette.primary.main};
  font-size: 16px;
  background-color: #fff;
  border: none;
  margin: 30px 0;
  cursor: pointer;
`
export const SelectFileBox = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  margin: 2px 0 30px 0;
  @media (max-width: ${md}px) {
    width: 45%;
  }
  div {
    align-items: center;
    p {
      margin: 0;
      text-align: center;
    }
  }
  input[type="file"] {
    display: none;
  }
  label {
    width: 57%;
    text-align: center;
    display: block;
    //max-height: 40px;
    //aspect-ratio: 10/1;
    padding: 7px 5px;
    border-radius: 4px;
    cursor: pointer;
    //background-color: #3b81f7;
    color: #3b81f7;
    border: 1px solid #3b81f7;
    margin: 5px 0;
    font-size: 13px;
    /* &:hover {
      background-color: #3b81f7dd;
    } */
  }
`
