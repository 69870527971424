// Arquivo criado: 19/07/2023 às 11:32
import { combineReducers } from 'redux'
import { userReducer } from './user.reducer'
import { globalMessageReducer } from './globalMessage.reducer'
import { eventReducer } from './event.reducer'
import { backDropLoadingReducer } from './backDropLoading.reducer'

export const rootReducer = combineReducers({
  user: userReducer,
  globalMessage: globalMessageReducer,
  event: eventReducer,
  backDropLoading: backDropLoadingReducer
})
