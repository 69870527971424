// Arquivo criado: 18/07/2023 às 23:01
import React from 'react'
import * as S from './styles'
import einsteinLogo from '../../assets/sem_fundo_einstein.png'

export const Navbar = (): JSX.Element => {

  return (
    <S.Container>
      <S.Box>
        <img src={einsteinLogo} width={'6%'} />
      </S.Box>
    </S.Container>
  )
}
