// Arquivo criado: 13/07/2023 às 18:32
import React from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { Home } from './pages/home'
import { Login } from './pages/login'
import { Profile } from './pages/profile'
import { Works } from './pages/works'
import { NotFound } from './pages/notFound'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from './redux/store'
import { BaseContent } from './components/BaseContent'
import { Submission } from './pages/submission'
import { backEnd } from './utils/backend.util'
import { setEventAction } from './redux/actions/event.action'
import { Notifications } from './pages/notifications'
import { setNotificationUser, setLoginUser, setUser } from './redux/actions/user.action'
import { Dashboard } from './pages/dashboard'
import { LoginAdmin } from './pages/loginAdmin'
import { Events } from './pages/events'
import { Settings } from './pages/settings'
import { Help } from './pages/help'
import axios from 'axios'
import { googleConfig } from './config/google'
import { setOpenGlobalMessageAction } from './redux/actions/globalMessage.action'
import { AssignWorks } from './pages/assignWorks'
import { CreateEvaluator } from './pages/createEvaluator'
import { Reports } from './pages/reports'
import { DashboardEvaluator } from './pages/dashboardEvaluator'
import { NotificationsEvaluator } from './pages/notificationsEvaluator'
import { LoginEvaluator } from './pages/loginEvaluator'
import { CreateEditItems } from './pages/createEditItems'

export const RoutesController = (): JSX.Element => {

  const isLogged = useSelector((state: RootState) => state.user.isLogged)
  const needInfos = useSelector((state: RootState) => state.user.perfil?.needInfos)
  const userId = useSelector((state: RootState) => state.user.perfil?.id)
  const accountType = useSelector((state: RootState) => state.user.perfil?.accountType)
  const event = useSelector((state: RootState) => state.event.data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()

  const [isLoggedIn, setIsLoggedIn] = React.useState(isLogged)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      if (event) return

      const response = await backEnd('GET', 'events/default')

      if (!response.ok) {
        return
      }

      dispatch(setEventAction(response.data))
    }

    void getData()
  }, [dispatch, event])

  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (!isLogged) return
      if (!userId) return
      if (accountType !== 'user') return

      const response = await backEnd('GET', `notifications/user/${userId}?count=true`)

      if (response.ok) {
        dispatch(setNotificationUser(response.data))
      }

    }, 60000)

    return () => { clearInterval(interval) }
  }, [dispatch, isLogged, userId, accountType])

  React.useEffect(() => {
    setIsLoggedIn(isLogged)
    const getData = async (): Promise<void> => {
      if (!isLogged) {
        const params = new URLSearchParams(search)
        const code = params.get('code')
        const evaluator = localStorage.getItem('evaluator')

        const basePath = window.location.pathname.split('/')[1]

        if (basePath === 'admin' && !code) {
          navigate('/admin/login')
          return
        }

        if (basePath === 'avaliador' && !code) {
          navigate('/avaliador/login')
          return
        }

        if (!code) {
          navigate('/login')
          return
        }

        try {

          const saveInfos = (data: any, redirect: string): void => {
            const { token, ...user } = data

            dispatch(setLoginUser({
              isLogged: true,
              token
            }))

            dispatch(setUser(user))

            if (data.needInfos) {
              navigate('/perfil')

              return
            }

            navigate(redirect)

          }

          if (evaluator) {
            const response = await backEnd('POST', `evaluators/sign-in-google?code=${code}`)
            localStorage.removeItem('evaluator')

            if (!response.ok) {
              dispatch(setOpenGlobalMessageAction({
                modal: {
                  title: 'Não foi possível fazer o login'
                },
                message: response.msg,
                type: 'error'
              }))

              navigate('/avaliador/login')
              return
            }

            saveInfos(response.data, '/')
            return
          }

          const responseToken = await axios.post('https://oauth2.googleapis.com/token', {
            code,
            client_id: googleConfig.clientId,
            client_secret: googleConfig.clientSecret,
            redirect_uri: googleConfig.redirectUri,
            grant_type: 'authorization_code'
          })

          if (responseToken.data) {
            if (responseToken.data.access_token) {

              const accessToken = responseToken.data.access_token as string || ''

              const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                  Authorization: `Bearer ${accessToken}`
                }
              })

              if (userInfo.data) {
                const { email } = userInfo.data

                if (email) {
                  const signIn = await backEnd('POST', 'users/sign-in', { email })

                  if (!signIn.ok) {
                    dispatch(setOpenGlobalMessageAction({
                      modal: {
                        title: 'Não foi possível fazer o login'
                      },
                      message: signIn.msg,
                      type: 'error'
                    }))

                    navigate('/login')
                    return

                  }

                  if (signIn.data.needInfos) {

                    dispatch(setOpenGlobalMessageAction({
                      message: signIn.msg,
                      modal: {
                        title: 'Tudo certo!',
                        onConfirm: {
                          text: 'Preencher informações',
                          action: () => { saveInfos(signIn.data, '/') }
                        },
                        dimissable: false
                      },
                      type: 'success'
                    }))
                    return
                  }
                  saveInfos(signIn.data, '/')
                }

              }

            }
          }

        } catch (error) {
          dispatch(setOpenGlobalMessageAction({
            message: 'Não foi possível fazer login com o Google. Tente novamente mais tarde.',
            type: 'error'
          }))

          navigate('/login')
        }
      }
    }

    void getData()

  }, [dispatch, isLogged, navigate, search])

  return (
    <BaseContent accountType={accountType} isLogged={isLoggedIn}>
      <Routes>
        {
          isLoggedIn
            ? (
              <>
                <Route path="*" element={<NotFound />} />
                {
                  (accountType !== 'admin' && accountType !== 'evaluator') && (
                    <>
                      <Route path="/perfil" element={<Profile />} />
                      {
                        !needInfos && (
                          <>
                            <Route path="/" element={<Home />} />
                            <Route path="/submissao" element={<Submission />} />
                            <Route path="/trabalhos" element={<Works />} />
                            <Route path="/notificacoes" element={<Notifications />} />
                            <Route path="/ajuda" element={<Help />} />
                          </>
                        )
                      }
                    </>
                  )
                }
                {
                  accountType === 'admin' && (
                    <>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/admin/eventos" element={<Events />} />
                      <Route path="/admin/configuracoes" element={<Settings />} />
                      <Route path="/admin/distribuir-trabalhos" element={<AssignWorks />} />
                      <Route path="/admin/avaliadores" element={<CreateEvaluator />} />
                      <Route path="/admin/relatorios" element={<Reports />} />
                      <Route path="/admin/editar-conteudo" element={<CreateEditItems />} />
                      <Route path="/admin/login" element={<LoginAdmin />} />

                    </>
                  )
                }
                {
                  accountType === 'evaluator' && (
                    <>
                      <Route path="/" element={<DashboardEvaluator />} />
                      <Route path="/avaliador" element={<DashboardEvaluator />} />
                      <Route path="/avaliador/notificacoes" element={<NotificationsEvaluator />} />
                    </>
                  )
                }
              </>
            )
            : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/admin/login" element={<LoginAdmin />} />
                <Route path="/avaliador/login" element={<LoginEvaluator />} />
              </>
            )
        }
      </Routes>
    </BaseContent>
  )
}
