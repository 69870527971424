import { styled } from 'styled-components'

export const CustomInput = styled.input`
  width: 100%;
  aspect-ratio: 10/1;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 8px;
  margin: 7px 0;
  outline: none;
  &:focus {
    border: 1px solid rgba(94, 53, 177, 0.9);
  }
  &:placeholder-shown {
   font-style: italic;
  }
  &:disabled {
    color: #555;
  }
`
