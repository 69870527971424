// Arquivo criado: 24/07/2023 às 11:21

import { type IEvent } from '../../interfaces/IEvent'
import { type SetEventAction } from '../actions/event.action'

interface Payload {
  data: IEvent | null
}

const initialState: Payload = {
  data: null
}

export const eventReducer = (
  state = initialState,
  action: SetEventAction
): Payload => {
  switch (action.type) {
  case 'SET_EVENT':
    return {
      ...state,
      data: action.payload
    }
  default:
    return state
  }
}
