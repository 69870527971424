import { type Action } from 'redux'
import { type IGlobalMessage } from '../../interfaces/IGlobalMessage'

export const SET_OPEN_GLOBAL_MESSAGE = 'SET_OPEN_GLOBAL_MESSAGE'

export interface SetOpenGlobalMessageAction extends Action<typeof SET_OPEN_GLOBAL_MESSAGE> {
  payload: IGlobalMessage
}

export const setOpenGlobalMessageAction = (payload: IGlobalMessage): SetOpenGlobalMessageAction => ({
  type: SET_OPEN_GLOBAL_MESSAGE,
  payload
})
