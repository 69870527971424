import styled from 'styled-components'

interface IColumn {
  width?: number
}

const md = 1090

export const Container = styled.div`
  color: #ccc;
  padding: 10px;
  margin: 0;
  .rightColumn {
    //align-self: stretch;
    justify-content: space-between;
    /* @media (max-width: ${md}px) {
      align-self: center;
    } */
  }
  .tableFirstRow {
    justify-content: flex-start;
    @media (max-width: ${md}px) {
      align-items: flex-end;
    }
    h5 {
      margin: 15px 0;
      border-bottom: 1px solid #3b81f7;
      white-space: nowrap;
      margin-right: 10px;
      @media (max-width: ${md}px) {

    }
  }
  
  }
  .tableRow {
    @media (max-width: ${md}px) {
      width: 49%;
    }
  }
  .tableColumn {
    width: 100%;
    @media (max-width: ${md}px) {
      flex-direction: row;
    }
    p {
      align-self: center;
    }
  }
  .tableData {
    justify-content: flex-start;
  }
  .bodyContainer {
    align-items: flex-start;
  }
  .worksInfo {
    margin-top: 20px;
  }
  h2 {
    margin: 10px 0;
  }
  span {
    color: #1e88e5;
    font-size: 1.10rem;
}
`
export const AskRegisterContainer = styled.div`
 width: 100%;
 aspect-ratio: 3/1;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 margin: auto;
 h3 {
  color: #000
 }
`
export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  @media (max-width: ${md}px) {
    flex-direction: row;
    align-items: center;
    }
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
  }
  .notifcationParagrafy {
    margin: 0;
    p {
      text-align: justify;
      font-size: 13px;
    }
  }
  
`
export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p, h3, h5 {
    color: #000;
  }
  .button {
    margin: 30px 0;
    cursor: pointer;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
`
export const ScreenContainer = styled.div`
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h2{
    color: rgba(94, 53, 177, 0.7);
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .buttonRow {
    justify-content: center;
    margin: 15px auto;
    button {
      padding: 15px;
      a {
        font-size: 17px;
      }
    }
  }
`
export const LinkStyle = styled.button`
  text-decoration: underline;
  color: rgb(30, 136, 229);
  font-size: 16px;
  background-color: #fff;
  border: none;
  margin: 0 0 20px 0;
`
export const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`
export const NotificationContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #3b81f766;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  h4 {
    margin: 2px 0;
    color: #222;
    text-align: left;
    word-wrap: break-word;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  p {
    margin: 0;
    font-size: 12px;
    color: #666;
  }
`
