// Arquivo criado: 19/07/2023 às 12:29
import React from 'react'
import { ContainerUser } from '../ContainerUser'
import { type IUser } from '../../interfaces/IUser'
import { ContainerAdmin } from '../ContainerAdmin'
import { ContainerEvaluator } from '../ContainerEvaluator'

interface Props {
  children: React.ReactNode
  isLogged: boolean
  accountType?: IUser['accountType']
}

export const BaseContent = ({ children, isLogged, accountType }: Props): JSX.Element => {

  if (isLogged) {
    switch (accountType) {
    case 'admin':
      return <ContainerAdmin>{children}</ContainerAdmin>
    case 'evaluator':
      return <ContainerEvaluator>{children}</ContainerEvaluator>
    default:
      return <ContainerUser>{children}</ContainerUser>
    }
  }

  return <>{children}</>

}
