// Arquivo criado: 06/10/2023 às 14:38
import React from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { type IBoard } from '../../interfaces/IBoard'
import { type IManagement } from '../../interfaces/IManagement'
import { type IWorkCategory } from '../../interfaces/IWorkCategory'
import { type IWorkModel } from '../../interfaces/IWorkModel'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { urlFile } from '../../utils/urlFile.util'
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'
import { theme } from '../../config/theme'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

interface Authors {
  boardId: number
  cellphone: string
  email: string
  managementId: number
  name: string
}

interface Evaluator {
  id: number
  email: string
  name: string
}

interface Status {
  id: number
  label: string
}

interface Works {
  apresentation: string | null
  authors: Authors[]
  board: {
    id: number
    label: string
  }
  boardId: number
  category: {
    id: number
    label: string
  }
  categoryId: number
  code: string
  confirmationSubmit: boolean
  costAnalysis: boolean
  createdAt: string
  deleted: boolean
  diversePopulations: boolean
  evaluator: Evaluator | null
  evaluatorId: number | null
  eventId: number
  id: number
  local: string
  management: {
    id: number
    label: string
  }
  managementId: number
  model: {
    id: number
    label: string
  }
  modelId: number
  nurse: boolean
  status: Status
  statusId: number
  summary: string
  tags: string
  title: string
  updatedAt: string
  userId: number
  exposed: boolean | null
}

export const Reports = (): JSX.Element => {

  const event = useSelector((state: RootState) => state.event.data)
  const dispatch = useDispatch()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [works, setWorks] = React.useState<Works[]>([])
  const [isSorted, setIsSorted] = React.useState(false)
  const [get, setGet] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [buttonLoading, setButtonLoading] = React.useState(false)

  const [boards, setBoards] = React.useState<IBoard[]>([])
  // const [selectedBoard, setSelectedBoard] = React.useState(0)
  const [shownBoard, setShownBoard] = React.useState('')
  const [managements, setManagements] = React.useState<IManagement[]>([])
  const [shownManagement, setShownManagement] = React.useState('')
  const [categories, setCategories] = React.useState<IWorkCategory[]>([])
  // const [selectedCategory, setSelectedCategory] = React.useState(0)
  const [shownCategory, setShownCategory] = React.useState('')
  const [models, setModels] = React.useState<IWorkModel[]>([])
  const [shownModel, setShownModel] = React.useState('')
  const [evaluators, setEvaluators] = React.useState<Evaluator[]>([])
  const [shownFilterEvaluator, setShownFilterEvaluator] = React.useState('')
  const [workStatus, setWorkStatus] = React.useState<Status[]>([])
  const [shownStatus, setShownStatus] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [shownSearch, setShownSearch] = React.useState('')

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [deletedItem, setDeleteItem] = React.useState<Works | null>(null)

  const [noEvaluators, setNoEvaluators] = React.useState('')
  const [presentationType, setPresentationType] = React.useState('')

  const [filePlace, setFilePlace] = React.useState<File | undefined>(undefined)

  // const [hasDownloaded, setHasDownloaded] = React.useState(false)
  const [openImport, setOpenImport] = React.useState(false)
  const [selectAll, setSelectAll] = React.useState(false)
  const [selectedItems, setSelectedItems] = React.useState<number[]>([])
  const [sortedEvaluators, setSortedEvaluators] = React.useState<Evaluator[]>([])
  const [pdfLoading, setPdfLoading] = React.useState(false)

  const [hasBoard, setHasBoard] = React.useState(false)
  const [hasManagement, setHasManagement] = React.useState(false)
  const [hasCategory, setHasCategory] = React.useState(false)
  const [hasModel, setHasModel] = React.useState(false)
  const [hasEvaluator, setHasEvaluator] = React.useState(false)
  const [hasStatus, setHasStatus] = React.useState(false)

  const [allWorks, setAllWorks] = React.useState<Works[]>([])
  const [boardWorks, setBoardWorks] = React.useState<Works[]>([])
  const [managementWorks, setManagementWorks] = React.useState<Works[]>([])
  const [categoryWorks, setCategoryWorks] = React.useState<Works[]>([])
  const [modelWorks, setModelWorks] = React.useState<Works[]>([])
  const [evaluatorWorks, setEvaluatorWorks] = React.useState<Works[]>([])
  const [statusWorks, setStatusWorks] = React.useState<Works[]>([])

  const [dialogDownloadFileWait, setDialogDownloadFileWait] = React.useState(false)
  const [dialogDownloadFile, setDialogDownloadFile] = React.useState<{ code: number, index: number, total: number } | null>(null)
  const [dialogDownloadFileFinish, setDialogDownloadFileFinish] = React.useState<string[]>([])
  const [dialogDownloadCount, setDialogDownloadCount] = React.useState(60)
  const [notifyingWork, setNotifyingWork] = React.useState<Works[]>([])
  const [notifyingCount, setNotifyingCount] = React.useState(1)

  // const limitReq = 20

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'Distribuição de trabalhos'

      if (!event) return

      setLoading(true)
      const response = await backEnd('GET', `works/event/${event.id}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setAllWorks(response.data)
      setWorks(response.data)

      if (get) {
        setGet(false)
      }

    }
    void getData()
  }, [dispatch, event, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const resBoards = await backEnd('GET', 'boards')
      const resCategories = await backEnd('GET', 'works/categories')

      if (!resBoards.ok || !resCategories.ok) {

        const message = resBoards.ok ? resCategories.msg : resBoards.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setBoards(resBoards.data)
      setCategories(resCategories.data)
      setManagements([])
    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const resEvaluator = await backEnd('GET', 'evaluators')
      const resStatus = await backEnd('GET', 'works/status')

      if (!resEvaluator.ok || !resStatus.ok) {

        const message = resEvaluator.ok ? resStatus.msg : resEvaluator.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setEvaluators(resEvaluator.data)
      setWorkStatus(resStatus.data)
    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {

    setSortedEvaluators(evaluators.sort(function (a, b) {
      return a.name.localeCompare(b.name, 'pt', { sensitivity: 'base' })
    }))

  }, [evaluators])

  React.useEffect(() => {

    let filteredArray = allWorks
    setShownSearch('')

    if (hasBoard) {
      const arr: Works[] = []
      for (let i = 0; i < boardWorks.length; i++) {
        if (filteredArray.filter(item => item.id === boardWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === boardWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasManagement) {
      const arr: Works[] = []
      for (let i = 0; i < managementWorks.length; i++) {
        if (filteredArray.filter(item => item.id === managementWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === managementWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasCategory) {
      const arr: Works[] = []
      for (let i = 0; i < categoryWorks.length; i++) {
        if (filteredArray.filter(item => item.id === categoryWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === categoryWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasModel) {
      const arr: Works[] = []
      for (let i = 0; i < modelWorks.length; i++) {
        if (filteredArray.filter(item => item.id === modelWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === modelWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasEvaluator) {
      const arr: Works[] = []
      for (let i = 0; i < evaluatorWorks.length; i++) {
        if (filteredArray.filter(item => item.id === evaluatorWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === evaluatorWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    if (hasStatus) {
      const arr: Works[] = []
      for (let i = 0; i < statusWorks.length; i++) {
        if (filteredArray.filter(item => item.id === statusWorks[i].id)[0] !== undefined) {
          arr.push(filteredArray.filter(item => item.id === statusWorks[i].id)[0])
        }
      }
      filteredArray = arr
    }

    setWorks(filteredArray)

  }, [allWorks, boardWorks, hasBoard, managementWorks, hasManagement, categoryWorks, hasCategory, modelWorks, hasModel, evaluatorWorks, hasEvaluator, statusWorks, hasStatus])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (dialogDownloadFileWait) {
        setDialogDownloadCount(dialogDownloadCount - 1)
      }
    }, 1000)

    return (): void => { clearInterval(interval) }
  }, [dialogDownloadCount, dialogDownloadFileWait])

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3b81f725',
      color: theme.palette.common.white,
      '&:last-child': {
        borderTopRightRadius: 6
      },
      '&:first-of-type': {
        borderTopLeftRadius: 6
      }
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const handleSelectBoard = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const board = boards.find(item => item.id === id)

    setShownManagement('')
    setShownCategory('')
    setShownModel('')

    if (!board) return
    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}?boardId=${board.id}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = [] // aqui passa um filter no allWorks
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasBoard(true)
    setBoardWorks(newArr)

    const response2 = await backEnd('GET', `managements/board/${board.id}`)
    setShownBoard(board.label)

    if (!response2.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response2.msg
      }))
      return
    }

    setManagements(response2.data)

  }

  const handleSelectManagement = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const management = managements.find(item => item.id === id)

    if (!management) return
    setShownManagement(management.label)

    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}?managementId=${management.id}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasManagement(true)
    setManagementWorks(newArr)
  }

  const handleSelectCategory = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const category = categories.find(item => item.id === id)
    setShownModel('')

    if (!category) return
    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}?categoryId=${category.id}`)
    // setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasCategory(true)
    setCategoryWorks(newArr)

    const response2 = await backEnd('GET', `works/models/category/${category.id}`)
    setShownCategory(category.label)

    if (!response2.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response2.msg
      }))
      return
    }

    setModels(response2.data)
  }

  const handleSelectModel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const model = models.find(item => item.id === id)
    if (!model) return
    setShownModel(model.label)

    if (!event) return

    const response = await backEnd('GET', `works/event/${event.id}?modelId=${model.id}`)
    // setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasModel(true)
    setModelWorks(newArr)
  }

  const handleSelectFilterEvaluator = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const filterEvaluator = sortedEvaluators.find(item => item.id === id)

    if (!filterEvaluator) return
    if (!event) return

    setShownFilterEvaluator(filterEvaluator.name)

    const response = await backEnd('GET', `works/event/${event.id}?evaluatorId=${filterEvaluator.id}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasEvaluator(true)
    setEvaluatorWorks(newArr)
  }

  const handleSelectStatus = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const status = workStatus.find(item => item.id === id)

    if (!status) return
    if (!event) return

    setShownStatus(status.label)

    const response = await backEnd('GET', `works/event/${event.id}?statusId=${status.id}`)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    const newArr: Works[] = []
    for (let i = 0; i < allWorks.length; i++) {
      for (let j = 0; j < response.data.length; j++) {
        if (allWorks[i].id === response.data[j].id) {
          newArr.push(allWorks[i])
        }
      }
    }

    setHasStatus(true)
    setStatusWorks(newArr)
  }

  const handleNoEvaluators = (): void => {
    const noEvaluator = works.filter(item => (item.evaluator === null))
    setNoEvaluators('Sem avaliador')
    setWorks(noEvaluator)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value)
  }

  const onClickSearch = (): void => {
    clearFilters()
    const filterdWorks = Number(search) ? allWorks.filter(item => item.code.includes(search.toLowerCase())) : allWorks.filter(item => item.title.toLowerCase().includes(search.toLowerCase()))
    setWorks(filterdWorks)
    setShownSearch(search)
    setSearch('')
  }

  const handlePresentationType = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const type = e.target.value
    let presentation: Works[] = []
    if (type === 'N') {
      presentation = works.filter(item => (item.exposed === true))
      setPresentationType('Trabalho Exposto')
    } else if (type === 'S') {
      presentation = works.filter(item => (item.exposed === false))
      setPresentationType('Apresentação Oral')
    } else {
      presentation = works.filter(item => (item.exposed === null))
      setPresentationType('Apresentação Não Definida')
    }
    setWorks(presentation)
    setSelectedItems([])
  }

  const clearFilters = (): void => {
    setShownBoard('')
    setShownCategory('')
    setShownFilterEvaluator('')
    setShownManagement('')
    setShownModel('')
    setShownStatus('')
    setNoEvaluators('')
    setPresentationType('')
    setSelectedItems([])
    setSelectAll(false)
  }

  const handleNoFilters = (): void => {

    setWorks(allWorks)
    setHasBoard(false)
    setHasManagement(false)
    setHasCategory(false)
    setHasModel(false)
    setHasEvaluator(false)
    setHasStatus(false)

    setBoardWorks([])
    setManagementWorks([])
    setCategoryWorks([])
    setModelWorks([])
    setEvaluatorWorks([])
    setStatusWorks([])

    setShownSearch('')
    setSearch('')
    clearFilters()

  }

  const handleSort = (): void => {
    const sortedArray = [...works]
    sortedArray.sort(function (a, b) {
      if (a.evaluator !== null && a.evaluator !== undefined && b.evaluator !== null && b.evaluator !== undefined) {
        if (a.evaluator.name < b.evaluator.name) return -1
        if (a.evaluator.name > b.evaluator.name) return 1
        return 0
      } else if ((a.evaluator === null || a.evaluator === undefined) && (b.evaluator !== null && b.evaluator !== undefined)) {
        return -1
      } else if ((a.evaluator !== null && a.evaluator !== undefined) && (b.evaluator === null || b.evaluator === undefined)) {
        return 1
      } else {
        return 0
      }
    })
    if (isSorted) {
      sortedArray.reverse()
      setIsSorted(false)
    } else {
      setIsSorted(true)
    }
    setWorks(sortedArray)
  }

  const handleSortByApresentation = (): void => {
    const sortedArray = [...works]
    sortedArray.sort(function (a, b) {
      if (a.apresentation !== null && a.apresentation !== undefined && b.apresentation !== null && b.apresentation !== undefined) {
        if (a.apresentation < b.apresentation) return -1
        if (a.apresentation > b.apresentation) return 1
        return 0
      } else if ((a.apresentation === null || a.apresentation === undefined) && (b.apresentation !== null && b.apresentation !== undefined)) {
        return -1
      } else if ((a.apresentation !== null && a.apresentation !== undefined) && (b.apresentation === null || b.apresentation === undefined)) {
        return 1
      } else {
        return 0
      }
    })
    if (isSorted) {
      sortedArray.reverse()
      setIsSorted(false)
    } else {
      setIsSorted(true)
    }
    setWorks(sortedArray)
  }

  const handleSortByPlace = (): void => {
    const sortedArray = [...works]
    sortedArray.sort(function (a, b) {
      if (a.local !== null && a.local !== undefined && b.local !== null && b.local !== undefined) {
        if (a.local < b.local) return -1
        if (a.local > b.local) return 1
        return 0
      } else if ((a.local === null || a.local === undefined) && (b.local !== null && b.local !== undefined)) {
        return -1
      } else if ((a.local !== null && a.local !== undefined) && (b.local === null || b.local === undefined)) {
        return 1
      } else {
        return 0
      }
    })
    if (isSorted) {
      sortedArray.reverse()
      setIsSorted(false)
    } else {
      setIsSorted(true)
    }
    setWorks(sortedArray)
  }

  const sortTable = (key: string): void => {
    const sortedArray = [...works]
    switch (key) {
    case 'title':
      sortedArray.sort(function (a, b) {
        return a.title.localeCompare(b.title, 'pt', { sensitivity: 'base' })
      })
      break
    case 'code':
      sortedArray.sort(function (a, b) {
        return a.code.localeCompare(b.code, 'pt', { sensitivity: 'base' })
      })
      break
    case 'createdAt':
      sortedArray.sort(function (a, b) {
        return a.createdAt.localeCompare(b.createdAt, 'pt', { sensitivity: 'base' })
      })
      break
    case 'status':
      sortedArray.sort(function (a, b) {
        return a.status.label.localeCompare(b.status.label, 'pt', { sensitivity: 'base' })
      })
      break
    case 'board':
      sortedArray.sort(function (a, b) {
        return a.board.label.localeCompare(b.board.label, 'pt', { sensitivity: 'base' })
      })
      break
    case 'model':
      sortedArray.sort(function (a, b) {
        return a.model.label.localeCompare(b.model.label, 'pt', { sensitivity: 'base' })
      })
      break
    default:
      return
    }

    if (isSorted) {
      sortedArray.reverse()
      setIsSorted(false)
    } else {
      setIsSorted(true)
    }
    setWorks(sortedArray)
  }

  const willDelete = (item: Works): void => {
    setDeleteItem(item)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = (): void => {
    setOpenDeleteModal(false)
  }

  const handleDelete = async (item: Works): Promise<void> => {

    if (!event) return
    if (!deletedItem) return

    setLoading(true)
    const response = await backEnd('DELETE', `works/${deletedItem.id}/user/${deletedItem.userId}`)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Trabalho excluído com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setOpenDeleteModal(false)
    setGet(true)

  }

  const uploadPlaceValidation = (file: File | undefined): void => {
    if (file) {

      const isSpreadSheet1 = /(\.xlsx)$/i
      const isSpreadSheet2 = /(\.xls)$/i

      if (!isSpreadSheet1.exec(file.name) && !isSpreadSheet2.exec(file.name)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O arquivo selecionado deve possuir extensão .xls ou .xlsx',
          type: 'error'
        }))

        return
      }

      if (file.name.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O nome do arquivo selecionado é maior que 255 caracteres',
          type: 'error'
        }))

        return
      }

      setFilePlace(file)
    }
  }

  const uploadFilePlace = async (): Promise<void> => {

    if (!filePlace) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, selecione uma planilha',
        type: 'error'
      }))

      return
    }
    const formData = new FormData()
    formData.append('file', filePlace)

    setLoading(true)
    const response = await backEnd(
      'PUT',
      'works/spreadsheet',
      formData,
      'multipart/form-data'
    )
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))

      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Importação de arquivo realizada com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setFilePlace(undefined)
    setOpenImport(false)
  }

  const getSpreadsheet = async (): Promise<void> => {

    if (!event) return

    setLoading(true)
    const url = await urlFile(`works/event/${event.id}/spreadsheet/full`)
    setLoading(false)

    const link = document.createElement('a')
    link.href = url
    link.download = 'submissoes.xlsx'
    link.click()

    window.URL.revokeObjectURL(url)

  }

  const getWorksPDF = async (): Promise<void> => {
    const worksIds = works.map(item => ({ id: item.id, code: item.code }))
    const cannotDownload: string[] = []

    if (worksIds.length < 1) return

    let needWait = false
    let i = 0

    const zip = new JSZip()
    setLoading(true)
    setPdfLoading(true)

    const getFile = async (index: number): Promise<void> => {

      if (needWait) {

        setDialogDownloadFileWait(true)
        await new Promise(resolve => setTimeout(resolve, 60000))
        setDialogDownloadFileWait(false)
        setDialogDownloadCount(60)

        needWait = false
      }

      if (index >= worksIds.length) {
        const zipBlob = await zip.generateAsync({ type: 'blob' })
        saveAs(zipBlob, 'documentos.zip')

        setPdfLoading(false)
        setLoading(false)
        setDialogDownloadFile(null)
        setDialogDownloadFileFinish([])
        setDialogDownloadFileWait(false)

        return
      }

      const id = worksIds[index].id
      const code = worksIds[index].code

      setDialogDownloadFile({ code: id, index, total: worksIds.length })
      const url = await urlFile(`works/${id ?? 0}/file`, true)

      if ((url as any)?.status === 429) {
        needWait = true
        void getFile(i)
        return
      }

      if (url.type !== 'application/pdf') {
        cannotDownload.push(code)
        setDialogDownloadFileFinish(cannotDownload)
      }

      i++
      zip.file(`trabalho-${code}.pdf`, url)

      void getFile(i)

    }

    void getFile(0)

  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!selectAll) {
      const countStart = page * rowsPerPage
      const countEnd = (works.length - countStart) > rowsPerPage ? rowsPerPage + countStart : works.length
      const select = works.length > rowsPerPage ? works.slice(countStart, countEnd) : works
      const ids = select.map((item: Works) => (
        item.id
      ))
      setSelectedItems(ids)
      setSelectAll(true)
    } else {
      setSelectedItems([])
      setSelectAll(false)
    }

  }

  const handleSelectWork = (e: React.ChangeEvent<HTMLInputElement>, id: number): void => {
    const _id = id
    const _work = works.find(item => item.id === _id)

    if (!_work) return

    if (selectedItems.includes(_work.id)) {
      setSelectedItems(
        selectedItems.filter(a =>
          a !== _work.id
        )
      )
      return
    }

    setSelectedItems([
      ...selectedItems,
      _work.id
    ])

  }

  const handleSelectAllWorks = (): void => {
    const ids = works.map((item: Works) => (
      item.id
    ))
    setSelectedItems(ids)
    setSelectAll(true)

  }

  const notifyAuthors = async (): Promise<void> => {

    const count = []

    if (selectedItems.length < 1) return

    setButtonLoading(true)
    setLoading(true)

    for (let i = 0; i < selectedItems.length; i++) {
      setNotifyingCount(i + 1)
      setNotifyingWork(works.filter(item => item.id === selectedItems[i]))
      const response = await backEnd('POST', `works/${selectedItems[i]}/notify/apresentation`)
      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        setButtonLoading(false)
        setLoading(false)
        return
      }
      await new Promise(resolve => setTimeout(resolve, 3300))
      count.push(selectedItems[i])

    }

    setButtonLoading(false)
    setLoading(false)

    dispatch(setOpenGlobalMessageAction({
      message: `Notificaç${count.length !== 1 ? 'ões' : 'ão'} enviada${count.length !== 1 ? 's' : ''} com sucesso para os autores de ${count.length} trabalho${count.length !== 1 ? 's' : ''} selecionado${count.length !== 1 ? 's' : ''}`,
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setNotifyingCount(1)
    setNotifyingWork([])

  }

  return (
    <S.Container>
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
      >
        <DialogContent>
          <div>
              Confirma a exclusão do trabalho <strong>{deletedItem?.title}</strong>? Esta ação não poderá ser desfeita
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={deletedItem !== null ? async () => { await handleDelete(deletedItem) } : handleCloseDeleteModal}>Excluir</Button>
          <Button onClick={handleCloseDeleteModal} autoFocus>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
                {
                  buttonLoading && (
                    <>
                      <h3>{notifyingCount}/{selectedItems.length} trabalhos</h3>
                      <p>Esta ação pode demorar um pouco. Por favor, não feche esta página.</p>
                      <p>Enviando notificação aos autores do trabalho:</p>
                      <p><strong>{notifyingWork[0].title}</strong></p>
                    </>
                  )
                }
                {
                  pdfLoading && (
                    <>
                      <p><br/> Esta ação pode demorar um pouco. Continue com esta janela aberta até a conclusão.</p>
                      {
                        dialogDownloadFile && (
                          <p>{dialogDownloadFile.index}/{dialogDownloadFile.total}</p>
                        )
                      }
                      {
                        dialogDownloadFileWait && (
                          <p>Aguarde um pouco... {dialogDownloadCount > 0 ? `${dialogDownloadCount} segundos` : ''}</p>
                        )
                      }
                      {
                        dialogDownloadFileFinish.length > 0 && (
                          <>
                            <p>Trabalhos que não puderam ser baixados: <br /><small>{dialogDownloadFileFinish.join(', ')}</small></p>
                          </>
                        )
                      }
                    </>
                  )
                }
              </S.LoadingContainer>
            )
            : (
              <>

                <S.RowContainer className='spreadSheetButtons'>
                  <Button variant='outlined' onClick={getWorksPDF}>Exportar Trabalhos PDF</Button>
                  <Button onClick={getSpreadsheet} variant='outlined'>Exportar Planilha de Dados</Button>
                  <Button onClick={() => { setOpenImport(true) }} variant='outlined'>Importar Planilha de Dados</Button>
                  <S.ColumnContainer width={35} style={{ alignSelf: 'start' }}>
                    {
                      openImport && (
                        <S.SelectFileBox>
                          <label htmlFor='file'>ESCOLHER ARQUIVO</label>
                          <input id='file' name='file' type='file' value={''} required accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' placeholder='Anexar Arquivo' onChange={(e) => { uploadPlaceValidation(e.target.files?.[0]) }}/>
                        </S.SelectFileBox>
                      )
                    }
                  </S.ColumnContainer>
                </S.RowContainer>
                <S.RowContainer>
                  {
                    (filePlace) && (
                      <>
                        <S.RowContainer style={{ justifyContent: 'center', backgroundColor: '#3b81f744', borderRadius: 8, marginBottom: 10 }}>
                          <p><strong>Arquivo selecionado:</strong> {filePlace.name}</p>
                        </S.RowContainer>
                        <S.RowContainer style={{ justifyContent: 'space-evenly' }}>
                          <Button variant='outlined' onClick={uploadFilePlace}>Anexar arquivo</Button>
                          <Button variant='outlined'onClick={() => { setFilePlace(undefined) }}>Excluir</Button>
                        </S.RowContainer>
                      </>
                    )
                  }
                </S.RowContainer>
                <S.RowContainer className='sp-bt top'>
                  <S.SelectsContainer>
                    <S.ColumnContainer width={28}>
                      <TextField className='select' fullWidth size='small' select id='status' name='status' value={shownStatus} onChange={handleSelectStatus} label='Status do Trabalho'>
                        <MenuItem value={shownStatus} style={{ display: 'none' }} disabled>{shownStatus}</MenuItem>
                        {
                          workStatus.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                          ))
                        }
                      </TextField>
                      <TextField className='select' fullWidth size='small' select id='board' name='board' value={shownBoard} onChange={handleSelectBoard} label='Diretoria'>
                        <MenuItem value={shownBoard} style={{ display: 'none' }} disabled>{shownBoard}</MenuItem>
                        {
                          boards.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                          ))
                        }
                      </TextField>
                      <TextField className='select' fullWidth size='small' select id='management' name='management' value={shownManagement} disabled={managements.length === 0} onChange={handleSelectManagement} label='Gerência'>
                        <MenuItem value={shownManagement} style={{ display: 'none' }} disabled>{shownManagement}</MenuItem>
                        {
                          managements.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                          ))
                        }
                      </TextField>
                    </S.ColumnContainer>
                    <S.ColumnContainer width={28}>
                      <TextField className='select' fullWidth size='small' select id='evaluators' name='evaluators' value={shownFilterEvaluator} disabled={evaluators.length === 0} onChange={handleSelectFilterEvaluator} label='Filtrar por Avaliador'>
                        <MenuItem value={shownFilterEvaluator} style={{ display: 'none' }} disabled>{shownFilterEvaluator}</MenuItem>
                        {
                          sortedEvaluators.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                          ))
                        }
                      </TextField>
                      <TextField className='select' fullWidth size='small' select id='category' name='category' value={shownCategory} onChange={handleSelectCategory} label='Categoria'>
                        <MenuItem value={shownCategory} style={{ display: 'none' }} disabled>{shownCategory}</MenuItem>
                        {
                          categories.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                          ))
                        }
                      </TextField>
                      <TextField className='select' fullWidth size='small' select id='modelTool' name='modelTool' value={shownModel} disabled={models.length === 0} onChange={handleSelectModel} label='Modelo / Ferramenta'>
                        <MenuItem value={shownModel} style={{ display: 'none' }} disabled>{shownModel}</MenuItem>
                        {
                          models.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                          ))
                        }
                      </TextField>
                    </S.ColumnContainer>
                    <S.ColumnContainer width={32} className='searchContainer'>
                      <TextField className='select' id="search" type="search" size='small' label="Pesquisar por palavra-chave" value={search} onChange={handleSearch} InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={onClickSearch}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      />
                    </S.ColumnContainer>
                  </S.SelectsContainer>
                </S.RowContainer>
                <S.RowContainer className='sp-bt filters'>
                  <S.RowContainer className='search'>
                    <p>Filtros aplicados: <span>{shownBoard} {shownCategory} {shownManagement} {shownModel} {shownStatus} {shownFilterEvaluator} {noEvaluators} {shownSearch} {presentationType}</span></p>
                  </S.RowContainer>
                  <Button variant='outlined' onClick={() => { handleNoFilters() }}>Limpar Filtros</Button>
                  <Button variant='outlined' onClick={handleNoEvaluators}>Ver trabalhos sem avaliador</Button>
                </S.RowContainer>
                <S.RowContainer className='color authorNotification'>
                  <S.ColumnContainer width={100}>
                    <p>Notificação de Autores</p>
                    <S.RowContainer>
                      <S.ColumnContainer width={28}>
                        <TextField className='select' fullWidth size='small' select id='presentation' name='presentation' value={presentationType} onChange={handlePresentationType} disabled={presentationType !== ''} label='Filtrar por Apresentação'>
                          <MenuItem value={presentationType} style={{ display: 'none' }} disabled>{presentationType}</MenuItem>
                          <MenuItem value={'N'}>Trabalho Exposto</MenuItem>
                          <MenuItem value={'S'}>Apresentação Oral</MenuItem>
                          <MenuItem value={'-'}>Apresentação Não Definida</MenuItem>
                        </TextField>
                      </S.ColumnContainer>
                      <S.ColumnContainer width={28} className='notificationContainer'>
                        {
                          (presentationType === 'Trabalho Exposto' || presentationType === 'Apresentação Oral') && (
                            <Button variant='text' onClick={handleSelectAllWorks} disabled={presentationType !== 'Trabalho Exposto' && presentationType !== 'Apresentação Oral'}>Selecionar Todos {(presentationType === 'Trabalho Exposto' || presentationType === 'Apresentação Oral') && works.length > 0 ? `(${works.length})` : null}</Button>
                          )
                        }
                      </S.ColumnContainer>
                      <S.ColumnContainer width={28} className='notificationContainer'>
                        <Button variant='outlined' onClick={notifyAuthors} disabled={selectedItems.length < 1 || buttonLoading || (presentationType !== 'Trabalho Exposto' && presentationType !== 'Apresentação Oral')}>{buttonLoading ? <CircularProgress /> : 'Notificar Autores'}</Button>
                      </S.ColumnContainer>
                    </S.RowContainer>
                  </S.ColumnContainer>
                </S.RowContainer>
                {
                  loading
                    ? (
                      <S.LoadingContainer>
                        <CircularProgress />
                      </S.LoadingContainer>
                    )
                    : (
                      <>
                        {
                          (works.length > 0 && works !== undefined && works !== null)
                            ? (
                              <>
                                <Paper>
                                  <TableContainer>
                                    <Table sx={{ minWidth: 1000 }}>
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell />
                                          <StyledTableCell><Button onClick={() => { sortTable('code') }}>Código</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => { sortTable('title') }}>Título</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => { sortTable('board') }}>Diretoria</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => { sortTable('model') }}>Modelo / Ferramenta</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => { sortTable('createdAt') }}>Data / Hora Submissão</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={() => { sortTable('status') }}>Status</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={handleSort}>Avaliador</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={handleSortByPlace}>Local</Button></StyledTableCell>
                                          <StyledTableCell><Button onClick={handleSortByApresentation}>Data / Hora Apresentação</Button></StyledTableCell>
                                          <StyledTableCell><Button>Apresentação</Button></StyledTableCell>
                                          <StyledTableCell><Checkbox checked={selectAll} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSelectAll(e) }} sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                              color: theme.palette.primary.main
                                            }
                                          }} />
                                          </StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {
                                          works.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                            <StyledTableRow key={index}>
                                              <StyledTableCell><Button className='button' onClick={() => { willDelete(item) }}><DeleteIcon className='icon button' /></Button></StyledTableCell>
                                              <StyledTableCell>{item.code}</StyledTableCell>
                                              <StyledTableCell>{item.title}</StyledTableCell>
                                              <StyledTableCell>{item.board.label}</StyledTableCell>
                                              <StyledTableCell>{item.model.label}</StyledTableCell>
                                              <StyledTableCell>{dayjs(item.createdAt).format('DD/MM/YYYY - HH:mm')}</StyledTableCell>
                                              <StyledTableCell style={item.status.id === 3 ? { color: 'green' } : item.status.id === 4 ? { color: 'orange' } : item.status.id === 5 ? { color: 'red' } : item.status.id === 6 ? { color: theme.palette.primary.main } : item.status.id === 1 ? { color: 'black' } : { color: 'darkgray' }}>{item.status.label}</StyledTableCell>
                                              <StyledTableCell>{item.evaluator !== null ? item.evaluator.name : '-'}</StyledTableCell>
                                              <StyledTableCell>{item.local !== null ? item.local : '-'}</StyledTableCell>
                                              <StyledTableCell>{item.apresentation !== null ? dayjs(item.apresentation).format('DD/MM/YYYY - HH:mm') : '-'}</StyledTableCell>
                                              <StyledTableCell>{item.exposed === null ? '-' : item.exposed ? 'Não' : 'Sim'}</StyledTableCell>
                                              <StyledTableCell><Checkbox checked={selectedItems.includes(item.id)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSelectWork(e, item.id) }} sx={{
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                  color: theme.palette.primary.main
                                                }
                                              }} /></StyledTableCell>
                                            </StyledTableRow>
                                          ))
                                        }
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    component="div"
                                    count={works.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage={'Trabalhos por página:'}
                                    labelDisplayedRows={function defaultLabelDisplayedRows ({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}` }}
                                  />
                                </Paper>
                              </>

                            )
                            : (
                              <h4>Não foram encontrados trabalhos submetidos neste evento</h4>
                            )
                        }
                      </>
                    )
                }
              </>
            )
        }
      </S.ScreenContainer>
    </S.Container>
  )
}
