// Arquivo criado: 18/07/2023 às 23:01
import React from 'react'
import * as S from './styles'

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export const CustomSelect: React.FC<Props> = ({
  children,
  onChange = () => {},
  ...props
}: Props) => {

  const [selected, setSelected] = React.useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    onChange(e)
    setSelected(true)
  }

  return (
    <S.CustomSelect selected={selected} onChange={handleChange} {...props}>
      {children}
    </S.CustomSelect>
  )
}
