// Arquivo criado: 11/08/2023 às 14:56

import { type AxiosResponse } from 'axios'
import { backEnd } from './backend.util'

export const urlFile: {
  (route: string, onlyBlob?: false): Promise<string>
  (route: string, onlyBlob?: true): Promise<Blob>
} = async (route: string, onlyBlob = false) => {
  const response: AxiosResponse = await backEnd(
    'GET',
    route,
    undefined,
    'application/json',
    'blob'
  ) as any
  try {

    if (onlyBlob && response?.status === 429) {
      return response
    }

    const blob = new Blob([response.data], { type: response.headers['content-type'] })

    if (onlyBlob) return blob as any

    const url = window.URL.createObjectURL(blob)

    return url
  } catch (e) {
    return e
  }
}
