import { type Action } from 'redux'
import { type IEvent } from '../../interfaces/IEvent'

export const SET_EVENT = 'SET_EVENT'

export interface SetEventAction extends Action<typeof SET_EVENT> {
  payload: IEvent
}

export const setEventAction = (payload: IEvent): SetEventAction => ({
  type: SET_EVENT,
  payload
})
