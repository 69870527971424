import styled from 'styled-components'

interface IRow {
  justifycontent?: string
}

interface IColumn {
  width?: number
}

const sm = 440
const md = 880
const lg = 1090

export const Container = styled.div`
  color: #ccc;
  margin: 0;
  padding: 10px;  
  .pageTitle {
    width: 95%;
    margin: 0 auto;
  }
  .countSubmissions {
    margin: 0 auto;
    width: 95%;
    @media (max-width: ${md}px) {
      width: 85%;
    }
    p {
      font-style: italic;
    }
    h4 {
      margin-bottom: 4px;
      color: #163fc6ff;
    }
  }
  p {
  color: #000;
  word-wrap: break-word;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis; 
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: ${sm}px) {
    font-size: 12px;
  }
  @media (min-width: ${sm}px) and (max-width: ${lg}px) {
    font-size: 13px;
  }
 }
 .button {
    margin: 0;
    padding: 0;
    max-width: fit-content;
    min-width: fit-content;
 }
`

export const ScreenContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h2{
    color: rgba(94, 53, 177, 0.7);
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
`

export const FormContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 margin-bottom: 20px;
 border-left: 1px solid #3b81f7;
 @media (max-width: ${md}px) {
  border-left-width: 0;
  width: 85%;
  }
 .tableTitle {
  height: 60px;
  background-color: #3b81f744;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #3b81f7;
  border-bottom: 1px solid #3b81f7;
  padding: 2px;
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    height: 40px;
    border-top-width: 0;
  }
  @media (max-width: ${sm}px) {
    height: 35px;
    border-top-width: 0;
  }
 }
 .tableColumn {
  justify-content: space-between;
  align-self: stretch;
  border-right: 1px solid #3b81f7;
  align-items: center;
 }
 .tableRow {
  height: 50px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #3b81f7;
  padding: 2px;
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    height: 40px;
  }
  @media (max-width: ${sm}px) {
    height: 35px;
  }
 }
 h4 {
  color: #000;
  text-align: center;
  @media (max-width: ${sm}px) {
    font-size: 12px;
  }
  @media (min-width: ${sm}px) and (max-width: ${lg}px) {
    font-size: 13px;
  }
 }
 
 .icon {
  color: #3b81f7dd;
  @media (min-width: ${md}px) and (max-width: ${lg}px) {
    font-size: 14px;
  }
 }
 .dlIcon {
  color: #333;
 }
 .responsiveTable {
  border: 1px solid #3b81f7;
  border-bottom-width: 0;
  margin: 0 auto 30px auto;
 }
`
export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 10}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;
`

export const RowContainer = styled.div<IRow>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifycontent }) => justifycontent ?? 'flex-start'};
  h2 {
    color: rgba(94, 53, 177, 0.7);
    //text-align: right;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  aspect-ratio: 2.5/1;
`
export const SummaryContainer = styled.div`
  //width: 100%;
  .title {
      margin-bottom: 10px; 
      padding-bottom: 5px;     
    }
  .summaryRow {
    justify-content: flex-start;
    p {
      margin: 0;
    }
    .secondItem {
    margin-left: 20px;
    }
  }
  .summaryTitle {
    margin-top: 0;
    font-size: 20px;
  }
  .middleRow {
    margin-top: 20px;
    @media (max-width: ${md}px) {
      margin-top: 15px;
    }
  } 
  .lastRow {
    margin-top: 15px;
  } 
  .authorsStep5 {
    margin-top: 0;
    align-self: flex-start;
  }
  .blueBorder {
    border: 0 solid #3b81f799;
    border-bottom-width: 1px;
    padding-bottom: 1px;
  }
  .topic {
    font-size: 16px;
  }
  .author {
    font-size: 14px;
  }
  .summaryTagsContainer {
    @media (max-width: ${md}px) {
      margin-top: 15px;
    }
  }
  .noBreak {
    @media (max-width: ${md}px) {
      flex-direction: row;
  }
  }
  .description {
    p {
      font-size: 13px;
    }
  }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 24px;
    padding: 15px;

`
export const SingleAuthor = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  @media (max-width: ${md}px) {
      justify-content: center;
    }
  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13.5px;
  }
`

export const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 2px;
  @media (max-width: ${md}px) {
    justify-content: center;
  }
  .summaryInLineItem {
    margin-right: 8px;
    font-size: 14px;
  }
`
