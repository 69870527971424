import styled from 'styled-components'
import { theme } from '../../config/theme'

interface IRow {
  justifycontent?: string
}

interface IColumn {
  width?: number
}

const sm = 440
const md = 880
const lg = 1090

export const Container = styled.div`
  color: #ccc;
  margin: 0;
  padding: 10px;  
`

export const ScreenContainer = styled.div`
 width: 90%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h2, h3{
    color: rgba(94, 53, 177, 0.7);
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .sp-bt {
    justify-content: space-between;
  }
  .css-1m9pwf3 {
    border: 1px solid #000;
  }
  .filters {
    width: 100%;
    // padding-bottom: 3px;
    margin: 7px 0 7px 0;
    border-bottom: 1px solid ${theme.palette.primary.main}
  }
  .top {
    margin-top: 15px;
  }
  p {
    color: #000;
  }
  span {
    color: #444;
  }
  .search {
    width: 55%;
  }
  .searchContainer {
    align-items: center;
  }
  .buttonContainer {
    //width: 70%;
    justify-content: flex-start;
    margin: 10px auto 2px auto;
    padding: 10px;
    button {
      margin-right: 10px;
      width: 20%;
    }
  }
  .searchContainer {
    align-items: flex-end;
    align-self: stretch;
    justify-content: start;
    button {
      margin-top: 8px;
      width: 100%;
    }
  }
  .notificationContainer {
    margin-bottom: 20px;
    align-items: center;
  }

  .notificationRow {
    justify-content: center;
  }

  .spreadSheetButtons {
    button {
      margin-right: 15px;
      margin-bottom: 25px;
    }
  }

  .color {
    border-bottom: 1px solid #3b81f7;
    margin-bottom: 7px;
  }

  .authorNotification {
    margin-bottom: 35px;
  }
  /* .lastLine {
    justify-content: flex-start;
    background-color: #62d;
    align-items: start ;
  } */
`

export const SelectsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectEvaluatorContainer = styled.div`
  width: 70%;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  margin: 5px auto;
  p {
    color: #444;
  }
  .input {
    width: 60%;
  }
`

export const FormContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 margin-bottom: 20px;
 border-left: 1px solid #3b81f7;
 @media (max-width: ${md}px) {
  border-left-width: 0;
  width: 85%;
  }
 h4 {
  color: #000;
  text-align: center;
  @media (max-width: ${sm}px) {
    font-size: 12px;
  }
  @media (min-width: ${sm}px) and (max-width: ${lg}px) {
    font-size: 13px;
  }
 }
`
export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 10}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;
  .select {
    margin-bottom: 8px;
  }
`

export const RowContainer = styled.div<IRow>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifycontent }) => justifycontent ?? 'flex-start'};
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  aspect-ratio: 2.5/1;
`
export const SelectFileBox = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // align-self: stretch;
  justify-content: center;
  // margin: 2px 0 30px 0;
  @media (max-width: ${md}px) {
    width: 45%;
  }
  /* div {
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      text-align: center;
    }
  } */
  input[type="file"] {
    display: none;
  }
  label {
    width: 60%;
    text-align: center;
    display: block;
    padding: 7px 5px;
    border-radius: 4px;
    cursor: pointer;
    color: #3b81f7;
    border: 1px solid #3b81f7;
    margin: 5px 0;
    font-size: 13px;
  }
  `
