import { styled } from 'styled-components'

interface ICustomSelectProps {
  selected: boolean
}

export const CustomSelect = styled.select<ICustomSelectProps>`
  width: 100%;
  aspect-ratio: 10/1;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 8px;
  //text-align: center;
  font-style: ${({ selected }) => (selected ? 'normal' : 'italic')};
  margin: 7px 0;
  background-color: #fff;
  color: ${({ selected }) => (selected ? '#000' : '#666')};
  outline: none;
  &:focus {
    border: 1px solid rgba(94, 53, 177, 0.9);
  }
  option {
    font-style: normal;
  }
  option:checked {
    color: #000;
  }
`
