import { styled } from 'styled-components'

export const Container = styled.div`
  a{
    color: inherit;
    text-decoration: none;
  }
  .toolbar {
    justify-content: space-between;
  }
  label {
    color: #444;
  }
  .MuiTextField-root {
    width: 100%;
  }
  .MuiSelect-nativeInput {
    border: none;
  }
  .MuiInputBase-root {
    &::after {
      border: none;
    }
    &::before {
      border: none;
    }
    &:focus {
      border: none;
    }
    .MuiInput-input {
      //font-size: 25px;
      font-weight: bold;
      &:hover {
        border: none;
      }
    }
    /* margin-bottom: 0;
    padding: 5px;
    outline: none; */
  }
`
export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 30px 0;
`
export const IconsContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;  
  align-items: center;
`

export const SingleIconContainer = styled.button`
  background-color: #fff;
  border-width: 0;
  cursor: pointer;
  .profile {
    font-size: 50px;
    color: #888;
    margin: 0 5px;
  }
  .smallerIcon {
    font-size: 25px;
    margin: 0 5px;
    color: #444;
  }
  
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 2/1;
`

export const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 2px;
  border-bottom: 1px solid rgba(94, 53, 177, 0.2);
  margin-bottom: 10px;
  margin-top: 0;
  p {
  margin: 0;
  }
  .email {
    font-size: 12px;
  }
  .avatar {
    margin-bottom: 8px;
    width: 56px;
    height: 56px;
  }
   
`
