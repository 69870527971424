import styled from 'styled-components'

export const Container = styled.div`
  .information {
    font-style: italic;
  }
`
export const ScreenContainer = styled.div`
 width: 90%;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: flex-start;
 align-self: center;
 flex-wrap: wrap;
 margin: 0 auto;
 h2{
    color: rgba(94, 53, 177, 0.7);
  }
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  aspect-ratio: 2.5/1;
`
export const NotificationsContainer = styled.div`
  width: 100%;
`
export const ReadNotification = styled.div`
  width: 100%;
  display: flex;
`
export const BorderColumn = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 20px;
`
export const MessageContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
`
