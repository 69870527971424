// Arquivo criado: 14/07/2023 às 15:02
import React from 'react'
import * as S from './styles'

export const Help = (): JSX.Element => (
  <S.Container>
    <h4>Precisa de ajuda?</h4>
    <p>Entre em contato conosco através do e-mail <a href="mailto:exposicaodaqualidade@einstein.br">exposicaodaqualidade@einstein.br</a></p>
  </S.Container>
)
