// Arquivo criado: 14/08/2023 às 00:36
import React from 'react'
import * as S from './styles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Button from '@mui/material/Button'

interface Props {
  title: string
  isColumn: boolean
  read: boolean
  createdAt: string
  action: (params?: any) => any
}

export const NotificationBox = ({ title, read, createdAt, action, isColumn }: Props): JSX.Element => {

  return (
    <S.RowCOntainer>
      <S.ColumnContainer>
        <S.FirstRow>
          <S.TitleContainer>
            <h3 style={read ? { color: '#777' } : { color: '#222' }}>{title}</h3>
          </S.TitleContainer>
          {
            !isColumn && (
              <S.DateContainer>
                <p className='date'>{createdAt}</p>
              </S.DateContainer>
            )
          }
        </S.FirstRow>
        {
          isColumn && (
            <S.RowCOntainer>
              <p className='date'>{createdAt}</p>
            </S.RowCOntainer>
          )
        }
      </S.ColumnContainer>
      <S.IconCOntainer>
        <Button onClick={action}><ArrowForwardIosIcon className='icon'/></Button>
      </S.IconCOntainer>
    </S.RowCOntainer>
  )

}
