import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import { type RootState } from '../../redux/store'
import * as S from './styles'

export const BackDropLoading = (): JSX.Element => {

  const state = useSelector((state: RootState) => state.backDropLoading)

  return (
    <S.Container>
      <Backdrop
        className='backdrop-loading'
        open={state}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </S.Container>
  )
}
