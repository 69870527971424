import styled from 'styled-components'
import bg001 from '../../assets/bg001.jpg'
import bg002 from '../../assets/bg002.jpg'
import bg003 from '../../assets/bg003.jpg'
import bg004 from '../../assets/bg004.jpg'
import bg005 from '../../assets/bg005.jpg'
import bg006 from '../../assets/bg006.jpg'

const sortBg = (): string => {
  const bgImages = [bg001, bg002, bg003, bg004, bg005, bg006]
  const image: number = Math.floor(Math.random() * bgImages.length)
  return bgImages[image]
}

const xs = 400
const sm = 600
const md = 800
const lg = 1000
const xl = 1260
const xxl = 1400

export const Container = styled.div`
  flex: 1;
  height: 100vh;
  box-sizing: border-box;
  color: #ccc;
  margin: 0;
  //background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.98), rgba(0, 0, 9, 0.80), rgba(0, 0, 0, 0.98)), url(${sortBg()});
  // background-image: linear-gradient(to bottom, rgba(0, 0, 9, 0.98), rgba(0, 0, 9, 0.40), rgba(0, 0, 9, 0.7), rgba(0, 0, 9, 0.9)), url(${bg006});
  // background-image: linear-gradient(to bottom, rgba(0, 0, 9, 0.98), rgba(0, 0, 9, 0.40), rgba(0, 0, 9, 0.7), rgba(0, 0, 9, 0.9)), url(process.env.REACT_APP_API_URL/addons/login-background);
  background-size: cover;
  background-position: center;
  @media (max-width: ${sm}px) {
    height: 100%;
  }
`
export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  aspect-ratio: 2.45/1; 
`

export const RowContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${sm}px) {
    flex-direction: column;
  }
`
export const LogoContainer = styled.div`
  box-sizing: border-box;
  width: 25%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  img {
    @media (max-width: ${sm}px) {
      width: 40%;
      /* position: relative;
      top: 80px; */
    }
    @media (min-width: ${sm}px) {
      width: 65%
    }
    @media (min-width: ${sm}px) and (max-width: ${md}px) {
      width: 70%;
    }
  }
  @media (max-width: ${sm}px) {
    width: 100%;
    aspect-ratio: 2.2/1;
    margin: 25px auto 0 auto;
  }
`
export const FormContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px auto;
  border-radius: 8px;
  padding: 10px;
  margin-top: -50px;
  p {
    color: #fff;
  }
  @media (max-width: ${sm}px) {
    width: 100%;
    margin-top: 0;
  }
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    margin-top: -10px;
  }
  @media (min-width: ${md}px) and (max-width: ${xl}px) {
    margin-top: 0;
  }
  @media (min-width: ${xxl}px) {
    width: 40%;
  }
  .buttonsBox{
    margin: 0 auto;
    justify-content: center;
    //margin-bottom: 22px;
    @media (max-width: ${sm}px) {
      margin-top: 0;
    }
    @media (min-width: ${xs}px) and (max-width: ${xl}px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
    }
    .googleButton {
      @media (min-width: ${xl}px) {
        margin-right: 8px;
        width: 45%;
      }
    }
    .facebookButton {
      @media (min-width: ${xl}px) {
        margin-left: 8px;
        width: 45%;
      }
    }
    button {
      //min-width: 38%;
      background-color: #fff;
      border-color: #3b81f7;
      color: #3b81f7;
      box-shadow: 1px 1px 3px #555;
      padding: 10px;
      &:hover {
        background-color: #fffc;
      }
      @media (min-width: ${sm}px) and (max-width: ${md}px) {
        width: 70%;
        font-size: 10px;
        margin: 10px;
      }
      @media (min-width: ${md}px) and (max-width: ${xl}px) {
        width: 70%;
        margin: 8px auto;
      }
      @media (max-width: ${sm}px) {
        width: 75%;
        margin: 10px auto;
      }
      @media (max-width: ${xs}px) {
        width: 85%;
        margin: 10px auto;
      }
    }
  }
`
export const TextContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  p {
    font-size: 20px;
  }
  /* .or {
    margin-top: 0;
  } */
  .googleButton {
    background-color: #fff;
    border-color: #3b81f7;
    color: #3b81f7;
    box-shadow: 1px 1px 3px #555;
    padding: 10px;
    &:hover {
      background-color: #fffc;
    }
  }
  button {
    width: 45%;
    background-color: #3b81f7cc;
    color: #fff;
    margin: 0 auto 10px auto;
    box-shadow: 1px 1px 2px #000;
    padding: 10px;
    &:hover {
      background-color: #3b81f7;
      border-color: #3b81f7;
    }
    @media (min-width: ${sm}px) and (max-width: ${md}px) {
        width: 70%;
        font-size: 10px;
        margin: 10px;
      }
      @media (min-width: ${md}px) and (max-width: ${xl}px) {
        width: 70%;
        margin: 12px;
      }
      @media (max-width: ${sm}px) {
        width: 75%;
        margin: 10px auto;
      }
      @media (max-width: ${xs}px) {
        width: 85%;
        margin: 10px auto;
      }
  }
`

export const TitleContainer = styled.div`
  box-sizing: border-box;
  width: 45%;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  h1 {
    color: #fff;
    text-shadow: 1px 2px 5px #000;
    font-size: 40px;
    text-align: center;
    @media (max-width: ${sm}px) {
      font-size: 28px;
  }
  }
  @media (max-width: ${sm}px) {
    width: 100%;
    margin:20px auto;
    border-radius: 0;
    justify-content: center;
  }
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    margin-top: 10px;
    h1 {
      font-size: 20px;
    }
  }
  @media (min-width: ${md}px) and (max-width: ${lg}px) {
    margin-top: 10px;
  }
`
export const LinkStyle = styled.a`
  text-decoration: none;
  color: rgb(30, 136, 229);
`
