import { type SetOpenBackDropLoading } from '../actions/backDropLoading.action'

const initialState = false

export const backDropLoadingReducer = (
  state = initialState,
  action: SetOpenBackDropLoading
): boolean => {
  switch (action.type) {
  case 'SET_OPEN_BACKDROP_LOADING':
    return action.payload
  default:
    return state
  }
}
