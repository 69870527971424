import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { Link } from 'react-router-dom'
import { theme } from '../../config/theme'

interface Props {
  handleDrawerClose?: () => void
}

export const ListLinksEvaluator = ({ handleDrawerClose }: Props): JSX.Element => {

  const links = [
    {
      label: 'Dashboard',
      icon: InsertChartOutlinedIcon,
      route: '/'
    },
    {
      label: 'Notificações',
      icon: EmailOutlinedIcon,
      route: '/avaliador/notificacoes'
    }
  ]

  return (
    <List>
      {links.map((info, index) => (
        <React.Fragment key={index}>
          <Link onClick={handleDrawerClose} style={{ color: 'inherit', textDecoration: 'none' }} to={info.route}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <info.icon color={location.pathname === info.route ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary={info.label} sx={{ color: location.pathname === info.route ? theme.palette.primary.main : 'inherit' }} />
              </ListItemButton>
            </ListItem>
          </Link>
        </React.Fragment>
      ))}
    </List>
  )
}
