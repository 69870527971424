// Arquivo criado: 17/10/2023 às 23:33
import React from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { type IWorkCategory } from '../../interfaces/IWorkCategory'
import { type IWorkModel } from '../../interfaces/IWorkModel'
import { urlFile } from '../../utils/urlFile.util'
import { theme } from '../../config/theme'
import { PDFDocument } from 'pdf-lib'

interface Questions {
  id: number
  question: string
  score: number | null
  title: string
  weight: number
}

interface SaveScore {
  questionId: number
  score: number | null
}

interface Status {
  id: number
  label: string
}

interface Works {
  id: number
  title: string
  score: number | null
  status: Status
  code: string
}

interface WorkInfo {
  avaliation: number | null
  category: {
    id: number
    label: string
  }
  model: {
    id: number
    label: string
    questions: Questions[]
  }
  title: string
  file: string | null
  status: Status
}

export const DashboardEvaluator = (): JSX.Element => {

  const event = useSelector((state: RootState) => state.event.data)
  const userId = useSelector((state: RootState) => state.user.perfil?.id)
  const dispatch = useDispatch()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [works, setWorks] = React.useState<Works[]>([])
  const [isSorted, setIsSorted] = React.useState(false)
  const [get, setGet] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [openModal, setOpenModal] = React.useState(false)
  const [openCloseModal, setOpenCloseModal] = React.useState(false)
  const [selectedWork, setSelectedWork] = React.useState<WorkInfo | null>(null)
  const [selectedWorkId, setSelectedWorkId] = React.useState<number | null>(null)
  const [workStatus, setWorkStatus] = React.useState<Status | null>(null)
  const [status, setStatus] = React.useState(0)
  const [allStatus, setAllStatus] = React.useState<Status[]>([])
  const [usedStatus, setUsedStatus] = React.useState<Status[]>([])
  const [categories, setCategories] = React.useState<IWorkCategory[]>([])
  const [selectedCategory, setSelectedCategory] = React.useState<IWorkCategory | null>(null)
  const [models, setModels] = React.useState<IWorkModel[]>([])
  const [selectedModel, setSelectedModel] = React.useState<IWorkModel | null>(null)
  const [allScores, setAllScores] = React.useState<Questions[]>([])
  const [filteredScores, setFilteredScores] = React.useState<Questions[]>([])
  const [justification, setJustification] = React.useState('')
  const [backupJustification, setBackupJustification] = React.useState('')
  const [justificationExplaining, setJustificationExplaining] = React.useState('')
  const [finalScore, setFinalScore] = React.useState(0)

  const [hasChanged, setHasChanged] = React.useState(false)

  const [fileUrl, setFileUrl] = React.useState<string | null>(null)
  const [inicialWorkStatus, setInicialWorkStatus] = React.useState<Status | null>(null)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'Trabalhos para Avaliação'

      if (!event) return
      if (!userId) return

      setLoading(true)
      const response = await backEnd('GET', `works/event/${event.id}/evaluator/${userId}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          modal: {
            title: 'Ops...'
          }
        }))
        return
      }

      setWorks(response.data)

      if (get) {
        setGet(false)
      }

    }

    void getData()

  }, [dispatch, event, userId, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const resCategories = await backEnd('GET', 'works/categories')

      if (!resCategories.ok) {

        const message = resCategories.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setCategories(resCategories.data)
      setModels([])
    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const resStatus = await backEnd('GET', 'works/status')

      if (!resStatus.ok) {

        const message = resStatus.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setAllStatus(resStatus.data)
    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {
    setFilteredScores(allScores.filter(value => value.score !== null))
  }, [allScores])

  React.useEffect(() => {
    const initialValue = 0
    const sumScores = allScores.map((item) => {
      return item.score !== null ? item.score * item.weight : 0 * item.weight
    })
    const media = (sumScores.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)) / allScores.length
    setFinalScore(parseFloat(media.toPrecision(3)))
  }, [allScores])

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3b81f725',
      color: theme.palette.common.white,
      '&:last-child': {
        borderTopRightRadius: 6
      },
      '&:first-of-type': {
        borderTopLeftRadius: 6
      }
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const handleSort = (): void => {
    const sortedArray = [...works]
    sortedArray.sort(function (a, b) {
      if (a.score !== null && a.score !== undefined && b.score !== null && b.score !== undefined) {
        if (a.score > b.score) return 1
        if (a.score < b.score) return -1
      }
      return 0
    })
    if (isSorted) {
      sortedArray.reverse()
      setIsSorted(false)
    } else {
      setIsSorted(true)
    }
    setWorks(sortedArray)
  }

  const sortTable = (key: string): void => {
    const sortedArray = [...works]
    switch (key) {
    case 'title':
      sortedArray.sort(function (a, b) {
        return a.title.localeCompare(b.title, 'pt', { sensitivity: 'base' })
      })
      break
    case 'code':
      sortedArray.sort(function (a, b) {
        return a.code.localeCompare(b.code, 'pt', { sensitivity: 'base' })
      })
      break
    case 'status':
      sortedArray.sort(function (a, b) {
        return a.status.label.localeCompare(b.status.label, 'pt', { sensitivity: 'base' })
      })
      break
    default:
      return
    }

    if (isSorted) {
      sortedArray.reverse()
      setIsSorted(false)
    } else {
      setIsSorted(true)
    }
    setWorks(sortedArray)
  }

  const handleCloseModal = (): void => {
    setSelectedWorkId(null)
    setSelectedWork(null)
    setAllScores([])
    setJustification('')
    setBackupJustification('')
    setJustificationExplaining('')
    setSelectedCategory(null)
    setSelectedModel(null)
    setFileUrl(null)
    setStatus(0)
    setWorkStatus(null)
    setOpenModal(false)
    setHasChanged(false)
    setInicialWorkStatus(null)
    if (openCloseModal) {
      setOpenCloseModal(false)
    }
  }

  const getWorkInfo = async (id: number): Promise<void> => {

    if (!userId) return
    setLoading(true)
    const response = await backEnd('GET', `works/${id}/evaluator/${userId}`)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        modal: {
          title: 'Ops...'
        }
      }))
      return
    }
    setUsedStatus(allStatus.filter(item => item.id === 3 || item.id === 4 || item.id === 5))
    setSelectedWork(response.data)
    setSelectedWorkId(id)
    setAllScores(response.data.model.questions)
    if (response.data.avaliation !== null) {
      setJustification(response.data.avaliation.justification)
      setBackupJustification(response.data.avaliation.justification)
    }
    setWorkStatus(response.data.status)
    setSelectedCategory(response.data.category)
    setSelectedModel(response.data.model)
    setInicialWorkStatus(response.data.status)
    setOpenModal(true)

    const url = await urlFile(`works/${id ?? 0}/file`)

    // setLoading(false)

    if (!url) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Não foi possível encontrar o arquivo'
      }))
      return
    }

    if (url) {

      const response = await fetch(url)
      const arrayBuffer = await response.arrayBuffer()
      const uint8Array = new Uint8Array(arrayBuffer)

      const pdfDoc = await PDFDocument.load(uint8Array)

      pdfDoc.removePage(0)

      const pdfBytes = await pdfDoc.save()

      const blob = new Blob([pdfBytes], { type: 'application/pdf' })

      if (pdfDoc.getPageCount() > 1) {
        setFileUrl(URL.createObjectURL(blob))
      }
    }

  }

  const handleSelectCategory = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const category = categories.find(item => item.id === id)
    setSelectedModel(null)

    if (!category) return

    setSelectedCategory(category)
    if (id !== selectedWork?.category.id) {
      setJustificationExplaining(justificationExplaining + `Alterar categoria para ${category.label}. `)
    }
    // setShownCategory(category.label)

    const response = await backEnd('GET', `works/models/category/${category.id}`)
    // setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setModels(response.data)
  }

  const handleSelectModel = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const model = models.find(item => item.id === id)
    if (!model) return
    setSelectedModel(model)
    if (id !== selectedWork?.model.id) {
      setJustificationExplaining(justificationExplaining + `Alterar Modelo / Ferramenta para ${model.label}. `)
    }
    // setShownModel(model.label)
  }

  const handleSelectedStatus = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const status = Number(e.target.value)
    const selectedStatus = allStatus.find(item => item.id === status)
    setStatus(status)
    if (status === 3 || status === 5) {
      // setJustification('')
      setJustificationExplaining('')
      setSelectedModel(null)
      setSelectedCategory(null)
    }

    if (status === 3) {
      setJustification('')
    } else {
      setJustification(backupJustification)
    }

    if (!selectedStatus) return
    setWorkStatus(selectedStatus)
  }

  const handleScoreInputs = (e: React.ChangeEvent<HTMLInputElement>, item: Questions): void => {
    let value = Number(e.target.value)
    const id = item.id

    const max = 10
    const min = 0

    if (value > max) value = max
    if (value < min) value = min

    setHasChanged(true)

    setAllScores(allScores.map(selected => { // altera o valor do score de cada questão na ficha de avaliação
      if (selected.id === id) {
        return { ...selected, score: value }
      } else {
        return selected
      }
    }))
  }

  const handleJustification = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setJustification(e.target.value)
    setBackupJustification(e.target.value)
  }

  const saveWork = async (): Promise<void> => {

    const filterScores: Questions[] = allScores.filter(value => value.score !== null)

    const saveScores: SaveScore[] = filterScores.map(score => {
      return {
        questionId: score.id,
        score: score.score
      }
    })

    if (status === 3 || status === 5) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Preencha os valores de todas as notas e clique em FINALIZAR AVALIAÇÃO para concluir a avaliação deste trabalho.'
      }))
      return
    }

    if ((status === 4 && justification === '') && (status === 4 && justificationExplaining === '')) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Justifique a solicitação de correção no campo JUSTIFICATIVA, ou selecione a nova Categoria e Modelo / Ferramenta aplicáveis, se for o caso.'
      }))
      return
    }

    if (!userId) return
    if (!selectedWorkId) return

    const data = {
      questions: saveScores,
      justification: (justification !== null && justification.length > 3) || (justificationExplaining !== null && justificationExplaining.length > 3) ? justificationExplaining + ' ' + justification : null,
      statusId: status !== 0 ? status : selectedWork?.status.id
    }

    setLoading(true)
    const response = await backEnd('POST', `works/${selectedWorkId}/evaluator/${userId}/avaliation`, data)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        modal: {
          title: 'Ops...'
        }
      }))
      return
    }

    setGet(true)

    handleCloseModal()

  }

  const finishEvaluation = async (): Promise<void> => {

    const filterScores: Questions[] = allScores.filter(value => value.score !== null)

    if (filterScores.length !== allScores.length && (status === 3 || status === 5)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Preencha os valores de todas as notas para concluir a avaliação deste trabalho.'
      }))
      return
    }

    const saveScores: SaveScore[] = filterScores.map(score => {
      return {
        questionId: score.id,
        score: score.score
      }
    })

    if ((status === 4 && justification === '') && (status === 4 && justificationExplaining === '')) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Justifique a solicitação de correção no campo JUSTIFICATIVA, ou selecione a nova Categoria e Modelo / Ferramenta aplicáveis, se for o caso.'
      }))
      return
    }

    if (!userId) return
    if (!selectedWorkId) return

    const data = {
      questions: saveScores,
      justification: (justification !== null && justification.length > 3) || (justificationExplaining !== null && justificationExplaining.length > 3) ? justificationExplaining + ' ' + justification : null,
      statusId: status !== 0 ? status : selectedWork?.status.id
    }

    setLoading(true)
    const response = await backEnd('POST', `works/${selectedWorkId}/evaluator/${userId}/avaliation`, data)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        modal: {
          title: 'Ops...'
        }
      }))
      return
    }

    setGet(true)
    handleCloseModal()

  }

  const handleOpenCloseConfirmation = (): void => {
    setOpenCloseModal(true)
    setFileUrl(null)
  }

  const handleCloseConfirmation = (): void => {
    setOpenCloseModal(false)
  }

  return (
    <S.Container>
      <Dialog
        open={openModal}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>Avaliação do Trabalho <strong>{selectedWork?.title}</strong></DialogTitle>
        <DialogContent>
          {
            (selectedWork !== null) && (
              <S.DialogContent>
                <S.DialogContentHeader>
                  <S.RowContainer style={{ width: '95%' }}>
                    <p>Categoria: <strong>{selectedWork.category.label}</strong></p>
                    {
                      fileUrl && (
                        <Button variant='outlined'><a href={fileUrl} target='_blank' rel="noreferrer" style={{ textDecorationLine: 'none', color: theme.palette.primary.main }}>Ver Trabalho</a></Button>
                      )
                    }
                  </S.RowContainer>
                  <p>Modelo / Ferramenta: <strong>{selectedWork.model.label}</strong></p>
                </S.DialogContentHeader>
                {
                  (allScores.length > 0)
                    ? (
                      <>
                        {
                          selectedWork.model.questions.map((item, index) => (
                            <S.RowContainer key={index} style={{ width: '95%' }}>
                              <S.QuestionContentBody>
                                <p className='question-title'>{index + 1}. {item.title}</p>
                                <p><em>{item.question}</em></p>
                              </S.QuestionContentBody>
                              <S.DialogContentBody width={15}>
                                <TextField placeholder='0 a 10' variant='standard' size='medium' id='score' name='score' label='Nota' value={allScores[index].score !== null ? allScores[index].score : ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleScoreInputs(e, item) }} InputProps={{ disableUnderline: true }} inputProps={{ type: 'number', min: 0, max: 10, maxLength: 2, style: { textAlign: 'center', width: '100%', alignSelf: 'center' } }} disabled={workStatus?.id !== 2 && workStatus?.id !== 4 && workStatus?.id !== 6} />
                              </S.DialogContentBody>
                            </S.RowContainer>
                          ))
                        }
                        <S.RowContainer style={{ width: '95%' }}>
                          <div style={{ width: '65%', display: 'flex', justifyContent: 'flex-end' }}>
                            <h5>Pontuação total:</h5>
                          </div>
                          <S.DialogContentBody width={15}>
                          <p style={{ textAlign: 'center', fontSize: '16px' }}><strong>{finalScore}</strong></p>
                          </S.DialogContentBody>
                        </S.RowContainer>
                      </>
                    )
                    : (
                      <p>Este Modelo / Ferramenta ainda não possui questões para avaliação.</p>
                    )
                }
                <S.RowContainer style={{ width: '95%', marginTop: 17, marginBottom: 2 }}>
                  <p><em>Ao alterar o status no botão abaixo e salvar você não poderá mais editar a avaliação deste trabalho (exceto trabalhos que sejam devolvidos para correção). Preencha os valores de todas as notas antes de avaliar este trabalho como <strong>Aceito</strong> ou <strong>Recusado</strong> e clique em <strong>Finalizar Avaliação</strong> para concluir.</em></p>
                </S.RowContainer>
                <S.RowContainer style={{ width: '95%', marginTop: 4, marginBottom: 17 }}>
                  <S.DialogContentBody width={30}>
                    {
                      usedStatus !== null && (
                        <TextField fullWidth variant='standard' size='small' select value={workStatus?.id} onChange={handleSelectedStatus} label='Status' InputProps={{ disableUnderline: true }} disabled={(inicialWorkStatus?.id === 3 || inicialWorkStatus?.id === 5)}>
                          <MenuItem value={workStatus?.id} style={{ display: 'none' }} disabled>{workStatus?.label}</MenuItem>
                          {
                            usedStatus.map((item, index) => (
                              <MenuItem key={index} disabled={item.id !== 4 && filteredScores.length !== allScores.length} value={item.id}>{item.id === 3 ? 'Aceito' : item.id === 4 ? 'Corrigir' : 'Recusado'}</MenuItem>
                            ))
                          }
                        </TextField>
                      )
                    }
                  </S.DialogContentBody>
                  <S.DialogContentBody width={30}>
                    <TextField variant='standard' className='select' fullWidth size='small' select id='category' name='category' value={selectedCategory?.id} onChange={handleSelectCategory} label='Categoria' InputProps={{ disableUnderline: true }} disabled={status !== 4}>
                      <MenuItem value={selectedCategory?.id} style={{ display: 'none' }} disabled>{selectedCategory?.label}</MenuItem>
                      {
                        categories.map((item, index) => (
                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                        ))
                      }
                    </TextField>
                  </S.DialogContentBody>
                  <S.DialogContentBody width={30}>
                    <TextField variant='standard' className='select' fullWidth size='small' select id='modelTool' name='modelTool' value={selectedModel?.id} disabled={models.length === 0 || status !== 4} onChange={handleSelectModel} label='Modelo / Ferramenta' InputProps={{ disableUnderline: true }}>
                      <MenuItem value={selectedModel?.id} style={{ display: 'none' }} disabled>{selectedModel?.label}</MenuItem>
                      {
                        models.map((item, index) => (
                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                        ))
                      }
                    </TextField>
                  </S.DialogContentBody>
                </S.RowContainer>
                <S.DialogContentBody width={95}>
                  <TextField fullWidth variant='standard' className='input' multiline rows={4} size='small' id='notificationMessage' name='notificationMessage' InputProps={{ disableUnderline: true }} value={justification} onChange={handleJustification} label='Justificativa' disabled={workStatus?.id !== 2 && workStatus?.id !== 4 && workStatus?.id !== 5 && workStatus?.id !== 6}/>
                </S.DialogContentBody>
              </S.DialogContent>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={(status !== 0 || hasChanged) ? handleOpenCloseConfirmation : handleCloseModal}>Fechar</Button>
          <Button onClick={saveWork} disabled={(status !== 0 && status !== 4) || (allScores.length < 1) || (inicialWorkStatus?.id === 3 || inicialWorkStatus?.id === 5)} autoFocus>Salvar Alterações</Button>
          <Button onClick={finishEvaluation} disabled={(filteredScores.length !== allScores.length) || status === 0 || allScores.length < 1}>Finalizar Avaliação</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCloseModal}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>Fechar sem salvar?</DialogTitle>
        <DialogContent>
          <div>
            <p>Se você ainda não definiu um status para este trabalho, pode salvar as alterações feitas e continuar depois.</p>
            <p>Caso você tenha selecionado o status <strong>Corrigir</strong>, poderá editar a avaliação após a correção do trabalho. {/* Caso tenha definido o status como <strong>Aceito</strong> ou <strong>Recusado</strong>, preencha todas as notas e clique em FINALIZAR AVALIAÇÃO para concluir. */}</p>
            <p>Se optar por fechar sem salvar você perderá as informações inseridas na avaliação deste trabalho.</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Fechar sem salvar</Button>
          <Button onClick={handleCloseConfirmation} autoFocus>Quero salvar</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (

              <>
                <h2>Sistema de Avaliações</h2>
                {
                  (works.length > 0 && works !== undefined && works !== null)
                    ? (
                      <>
                        <Paper>
                          <TableContainer>
                            <Table sx={{ minWidth: 1000 }}>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell><Button onClick={() => { sortTable('code') }}>Código</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('title') }}>Título</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('status') }}>Avaliação</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={() => { sortTable('status') }}>Status</Button></StyledTableCell>
                                  <StyledTableCell><Button onClick={handleSort}>Nota da Avaliação</Button></StyledTableCell>
                                  <StyledTableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  works.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell>{item.code}</StyledTableCell>
                                      <StyledTableCell>{item.title}</StyledTableCell>
                                      <StyledTableCell>{item.status.id === 2 ? 'Pendente' : item.status.id === 3 ? 'Aceito' : item.status.id === 4 || item.status.id === 6 ? 'Correção solicitada' : item.status.label}</StyledTableCell>
                                      <StyledTableCell style={item.status.id === 3 ? { color: 'green' } : item.status.id === 4 ? { color: 'orange' } : item.status.id === 5 ? { color: 'red' } : item.status.id === 6 ? { color: theme.palette.primary.main } : item.status.id === 1 ? { color: 'black' } : { color: 'darkgray' }}>{item.status.label}</StyledTableCell>
                                      <StyledTableCell align='center'>{item.score === null ? '-' : parseFloat(item.score.toPrecision(3))}</StyledTableCell>
                                      <StyledTableCell><Button className='button' onClick={() => { void getWorkInfo(item.id) }}><LibraryBooksOutlinedIcon /></Button></StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[20, 50, 100]}
                            component="div"
                            count={works.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={'Trabalhos por página:'}
                            labelDisplayedRows={function defaultLabelDisplayedRows ({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}` }}
                          />
                        </Paper>
                      </>

                    )
                    : (
                      <h4>Você não possui trabalhos para avaliar no momento</h4>
                    )
                }
              </>
            )
        }
      </S.ScreenContainer>
    </S.Container>
  )
}
