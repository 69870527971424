import styled from 'styled-components'

interface IRow {
  justifycontent?: string
}

const sm = 600
const md = 878
const lg = 1090

export const Container = styled.div`
  color: #ccc;
  margin: 0;
  padding: 10px;
  label {
    color: #444;
  }
  .MuiTextField-root {
    width: 100%;
    margin-bottom: 15px;
  }
`
export const TitleContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  @media (max-width: ${md}px) {
    width: 100%;
    margin: 10px auto;
    justify-content: center;
  }
  `

export const FormContainer = styled.div`
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 `

export const Form = styled.form`
  width: 80%;
 
`

export const ColumnContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 10px 35px 0;
  @media (max-width: ${md}px) {
    width: 85%;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    width: 75%;
  }
  `
export const RowContainer = styled.div<IRow>`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifycontent }) => justifycontent ?? 'space-between'};
  h2 {
    color: rgba(94, 53, 177, 0.7);
    text-align: right;
  }
  @media (max-width: ${lg}px) {
    button {
      font-size: 10px;
    }
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
    button {
      margin-top: 10px;
    }
  }
`
export const ButtonsContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  button {
    margin-right: 20px;
  }
  @media (max-width: ${lg}px) {
    button {
      font-size: 10px;
    }
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
    button {
      margin-top: 10px;
      margin-right: 0;
    }
  }

`
export const RowForInputs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .smallInput {
  @media (min-width: ${md}px) {
    width: 48%;
    aspect-ratio: 5/1;
  }
}
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  aspect-ratio: 2/1;
`
export const LabelInputContainer = styled.div` 
  width: 100%;
  p {
    color: #222;
    font-size: 11px;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 1px;
  }
  input, select {
    margin-top: 0
  }
`

export const LabelSmallInputContainer = styled.div` 
  width: 48%;
  @media (max-width: ${md}px) {
    width: 100%;
  }
  p {
    color: #222;
    font-size: 11px;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 1px;
  }
  input, select {
    margin-top: 0
  }
`
