// Arquivo criado: 06/09/2023 às 14:01
import React from 'react'

import * as S from './styles'
import Button from '@mui/material/Button'
import { type RootState } from '../../redux/store'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { useDispatch, useSelector } from 'react-redux'
import { backEnd } from '../../utils/backend.util'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

export const Events = (): JSX.Element => {

  const dispatch = useDispatch()

  const event = useSelector((state: RootState) => state.event.data)
  const [loading, setLoading] = React.useState(false)
  const [eventName, setEventName] = React.useState<string>('')
  const [enrollmentStartDate, setEnrollmentStartDate] = React.useState<Date | undefined>(undefined)
  const [maskEnrollmentStartDate, setMaskEnrollmentStartDate] = React.useState<string>('')
  const [enrollmentStartHour, setEnrollmentStartHour] = React.useState<string>('')
  const [enrollmentEndDate, setEnrollmentEndDate] = React.useState<Date | undefined>(undefined)
  const [maskEnrollmentEndDate, setMaskEnrollmentEndDate] = React.useState<string>('')
  const [enrollmentEndHour, setEnrollmentEndHour] = React.useState<string>('')
  const [evaluationStartDate, setEvaluationStartDate] = React.useState<Date | undefined>(undefined)
  const [maskEvaluationStartDate, setMaskEvaluationStartDate] = React.useState<string>('')
  const [evaluationStartHour, setEvaluationStartHour] = React.useState<string>('')
  const [evaluationEndDate, setEvaluationEndDate] = React.useState<Date | undefined>(undefined)
  const [maskEvaluationEndDate, setMaskEvaluationEndDate] = React.useState<string>('')
  const [evaluationEndHour, setEvaluationEndHour] = React.useState<string>('')
  const navigate = useNavigate()

  React.useEffect(() => {

    document.title = 'Coordenadoria de Eventos - Novo Evento'

  }, [])

  const handleForm = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
  }

  const handleEventName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEventName(event.target.value)
  }

  const maskDate = (value: string): string => {
    const v = value.replace(/\D/g, '').slice(0, 8)
    if (v.length >= 5) {
      return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
    } else if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`
    }
    return v
  }

  const maskHour = (value: string): string => {
    const v = value.replace(/\D/g, '').slice(0, 4)
    if (v.length >= 4) {
      return `${v.slice(0, 2)}:${v.slice(2, 4)}`
    }
    return v
  }

  const handleMaskEnrollmentStartDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskStartDate = maskDate(event.target.value)
    setMaskEnrollmentStartDate(maskStartDate)
    if (enrollmentStartHour !== '') {
      const dateParts = maskStartDate.split('/')
      const timeParts = enrollmentStartHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEnrollmentStartDate(date)
    }
  }

  const handleEnrollmentStartHour = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskStartHour = maskHour(event.target.value)
    setEnrollmentStartHour(maskStartHour)
    if (maskEnrollmentStartDate !== '') {
      const dateParts = maskEnrollmentStartDate.split('/')
      const timeParts = maskStartHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEnrollmentStartDate(date)
    }
  }

  const handleMaskEnrollmentEndDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskEndDate = maskDate(event.target.value)
    setMaskEnrollmentEndDate(maskEndDate)
    if (enrollmentEndHour !== '') {
      const dateParts = maskEndDate.split('/')
      const timeParts = enrollmentEndHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEnrollmentEndDate(date)
    }
  }

  const handleEnrollmentEndHour = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskEndHour = maskHour(event.target.value)
    setEnrollmentEndHour(maskEndHour)
    if (maskEnrollmentEndDate !== '') {
      const dateParts = maskEnrollmentEndDate.split('/')
      const timeParts = maskEndHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEnrollmentEndDate(date)
    }
  }

  const handleMaskEvaluationStartDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskStartDate = maskDate(event.target.value)
    setMaskEvaluationStartDate(maskStartDate)
    if (enrollmentStartHour !== '') {
      const dateParts = maskStartDate.split('/')
      const timeParts = evaluationStartHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEvaluationStartDate(date)
    }
  }

  const handleEvaluatonStartHour = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskStartHour = maskHour(event.target.value)
    setEvaluationStartHour(maskStartHour)
    if (maskEvaluationStartDate !== '') {
      const dateParts = maskEvaluationStartDate.split('/')
      const timeParts = maskStartHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEvaluationStartDate(date)
    }
  }

  const handleMaskEvaluationEndDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskEndDate = maskDate(event.target.value)
    setMaskEvaluationEndDate(maskEndDate)
    if (enrollmentEndHour !== '') {
      const dateParts = maskEndDate.split('/')
      const timeParts = evaluationEndHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEvaluationEndDate(date)
    }
  }

  const handleEvaluatonEndHour = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskEndHour = maskHour(event.target.value)
    setEvaluationEndHour(maskEndHour)
    if (maskEvaluationEndDate !== '') {
      const dateParts = maskEvaluationEndDate.split('/')
      const timeParts = maskEndHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEvaluationEndDate(date)
    }
  }

  const onHandleClick = async (): Promise<void> => {

    // validate Event name
    if (!eventName) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do novo evento deve ser informado.',
        type: 'error'
      }))

      return
    }

    if (!eventName.match(/^[\p{L}0-9 ?!,.:;'-]+$/u)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do evento não deve conter alguns caracteres espciais.',
        type: 'error'
      }))

      return
    }

    if (eventName.length > 255) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do evento não deve conter mais que 255 caracteres',
        type: 'error'
      }))

      return
    }

    // validate date / hour fields
    const today = new Date()
    const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1))

    // start Enrollment

    if (!enrollmentStartDate || enrollmentStartDate === undefined) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data e o horário de início das inscrições devem ser informados.',
        type: 'error'
      }))

      return
    }

    if (enrollmentStartDate < today) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data e o horário de início das inscrições não podem ser anteriores ao dia e à hora atuais.',
        type: 'error'
      }))

      return
    }

    if (enrollmentStartDate > oneYear) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de início das inscrições não deve passar de 1 ano a frente.',
        type: 'error'
      }))

      return
    }
    // date
    if (!maskEnrollmentStartDate || maskEnrollmentStartDate === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de início das inscrições deve ser informada.',
        type: 'error'
      }))

      return
    }

    if (!(/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(maskEnrollmentStartDate))) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Data de início das inscrições inválida.',
        type: 'error'
      }))

      return
    }
    // hour
    if (!enrollmentStartHour || enrollmentStartHour === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'O horário de início das inscrições deve ser informado.',
        type: 'error'
      }))

      return
    }

    if (!(/^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(enrollmentStartHour))) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Horário de início das inscrições inválido. O hoŕario deve conter 2 dígitos para hora (formato 24h) e 2 dígitos para os minutos.',
        type: 'error'
      }))

      return
    }

    // end Enrollment

    if (!enrollmentEndDate || enrollmentEndDate === undefined) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data e o horário de término das inscrições devem ser informados.',
        type: 'error'
      }))

      return
    }

    if (enrollmentEndDate < enrollmentStartDate) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de término das inscrições não pode ser anterior à data de início das inscrições.',
        type: 'error'
      }))

      return
    }

    if (enrollmentEndDate > oneYear) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de término das inscrições não deve passar de 1 ano a frente.',
        type: 'error'
      }))

      return
    }

    // date
    if (!maskEnrollmentEndDate || maskEnrollmentEndDate === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de início das inscrições deve ser informada.',
        type: 'error'
      }))

      return
    }

    if (!(/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(maskEnrollmentEndDate))) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Data de término das inscrições inválida.',
        type: 'error'
      }))

      return
    }

    // hour
    if (!enrollmentEndHour || enrollmentEndHour === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'O horário de término das inscrições deve ser informado.',
        type: 'error'
      }))

      return
    }

    if (!(/^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(enrollmentEndHour))) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Horário de término das inscrições inválido. O hoŕario deve conter 2 dígitos para hora (formato 24h) e 2 dígitos para os minutos.',
        type: 'error'
      }))

      return
    }

    // start Evaluation

    if (!evaluationStartDate || evaluationStartDate === undefined) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data e o horário de início das avaliações devem ser informados.',
        type: 'error'
      }))

      return
    }

    if (evaluationStartDate < today) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data e o horário de início das avaliações não podem ser anteriores ao dia e à hora atuais.',
        type: 'error'
      }))

      return
    }

    if (evaluationStartDate > oneYear) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de início das inscrições não deve passar de 1 ano a frente.',
        type: 'error'
      }))

      return
    }

    if (evaluationStartDate < enrollmentStartDate) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de início das avaliações não deve ser anterior à data de início das inscrições.',
        type: 'error'
      }))

      return
    }
    // date
    if (!maskEvaluationStartDate || maskEvaluationStartDate === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de início das avaliações deve ser informada.',
        type: 'error'
      }))

      return
    }

    if (!(/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(maskEvaluationStartDate))) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Data de início das avaliações inválida.',
        type: 'error'
      }))

      return
    }

    // hour
    if (!evaluationStartHour || evaluationStartHour === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'O horário de início das avaliações deve ser informado.',
        type: 'error'
      }))

      return
    }

    if (!(/^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(evaluationStartHour))) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Horário de início das avaliações inválido. O hoŕario deve conter 2 dígitos para hora (formato 24h) e 2 dígitos para os minutos.',
        type: 'error'
      }))

      return
    }

    // end Evaluation

    if (!evaluationEndDate || evaluationEndDate === undefined) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data e o horário de término das avaliações devem ser informados.',
        type: 'error'
      }))

      return
    }

    if (evaluationEndDate < evaluationStartDate) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de término das avaliações não pode ser anterior à data de início das avaliações.',
        type: 'error'
      }))

      return
    }

    if (evaluationEndDate > oneYear) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de término das avaliações não deve passar de 1 ano a frente.',
        type: 'error'
      }))

      return
    }

    if (evaluationEndDate < enrollmentEndDate) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de término das avaliações não deve ser anterior à data de término das inscrições.',
        type: 'error'
      }))

      return
    }

    // date
    if (!maskEnrollmentEndDate || maskEnrollmentEndDate === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'A data de início das inscrições deve ser informada.',
        type: 'error'
      }))

      return
    }

    if (!(/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(maskEnrollmentEndDate))) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Data de término das inscrições inválida.',
        type: 'error'
      }))

      return
    }

    // hour
    if (!evaluationEndHour || evaluationEndHour === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'O horário de término das avaliações deve ser informado.',
        type: 'error'
      }))

      return
    }

    if (!(/^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(evaluationEndHour))) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Horário de término das avaliações inválido. O hoŕario deve conter 2 dígitos para hora (formato 24h) e 2 dígitos para os minutos.',
        type: 'error'
      }))

      return
    }

    const data = {
      assessmentInitialDate: evaluationStartDate,
      assessmentFinalDate: evaluationEndDate,
      default: false,
      finalDate: enrollmentEndDate,
      initialDate: enrollmentStartDate,
      label: eventName
    }

    setLoading(true)
    const response = await backEnd('POST', 'events', data)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Evento criado com sucesso!',
      type: 'success',
      modal: {
        title: 'Evento criado com sucesso'
      }
    }))

    setEventName('')
    setEnrollmentStartDate(undefined)
    setMaskEnrollmentStartDate('')
    setEnrollmentStartHour('')
    setEnrollmentEndDate(undefined)
    setMaskEnrollmentEndDate('')
    setEnrollmentEndHour('')
    setEvaluationStartDate(undefined)
    setMaskEvaluationStartDate('')
    setEvaluationStartHour('')
    setEvaluationEndDate(undefined)
    setMaskEvaluationEndDate('')
    setEvaluationEndHour('')
  }

  const handleConfigurationClick = (): void => {
    navigate('/admin/configuracoes')
  }

  return (
    <S.Container>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <S.RowContainer style={{ alignItems: 'stretch' }}>
                <S.ColumnContainer width={55}>
                  <S.FormContainer>
                    <form onSubmit={handleForm}>
                      <S.FormGroupsContainer className='formTop'>
                        <TextField required size='small' id='eventName' name='eventName' value={eventName} onChange={handleEventName} label='Nome do Evento'/>
                      </S.FormGroupsContainer>
                      <S.FormGroupsContainer>
                        <S.RowContainer className='groupLine'>
                          <S.ColumnContainer className='groupTitle'>
                            <h4>Inscrições</h4>
                          </S.ColumnContainer>
                          <S.ColumnContainer className='groupInputs'>
                            <p>Início:</p>
                            <TextField required size='small' id='enrollmentStartDate' name='enrollmentStartDate' value={maskEnrollmentStartDate} onChange={handleMaskEnrollmentStartDate} label='dd/mm/aaaa'/>
                            <TextField required size='small' id='enrollmentStartHour' name='enrollmantStartHour' value={enrollmentStartHour} onChange={handleEnrollmentStartHour} label='--:--'/>
                          </S.ColumnContainer>
                          <S.ColumnContainer className='groupInputs'>
                            <p>Término:</p>
                            <TextField required size='small' id='enrollmentEndDate' name='enrollmentEndDate' value={maskEnrollmentEndDate} onChange={handleMaskEnrollmentEndDate} label='dd/mm/aaaa'/>
                            <TextField required size='small' id='enrollmentEndHour' name='enrollmentEndHour' value={enrollmentEndHour} onChange={handleEnrollmentEndHour} label='--:--'/>
                          </S.ColumnContainer>
                        </S.RowContainer>
                      </S.FormGroupsContainer>
                      <S.FormGroupsContainer>
                        <S.RowContainer className='groupLine'>
                          <S.ColumnContainer className='groupTitle'>
                            <h4>Avaliações</h4>
                          </S.ColumnContainer>
                          <S.ColumnContainer className='groupInputs'>
                            <p>Início:</p>
                            <TextField required size='small' id='evaluationStartDate' name='evaluationStartDate' value={maskEvaluationStartDate} onChange={handleMaskEvaluationStartDate} label='dd/mm/aaaa'/>
                            <TextField required size='small' id='evaluationStartHour' name='evaluationStartHour' value={evaluationStartHour} onChange={handleEvaluatonStartHour} label='--:--'/>
                          </S.ColumnContainer>
                          <S.ColumnContainer className='groupInputs'>
                            <p>Término:</p>
                            <TextField required size='small' id='evaluationEndtDate' name='evaluationEndtDate' value={maskEvaluationEndDate} onChange={handleMaskEvaluationEndDate} label='dd/mm/aaaa'/>
                            <TextField required size='small' id='evaluationEndtHour' name='evaluationEndtHour' value={evaluationEndHour} onChange={handleEvaluatonEndHour} label='--:--'/>
                          </S.ColumnContainer>
                        </S.RowContainer>
                      </S.FormGroupsContainer>
                      <S.ButtonContainer>
                        <Button onClick={onHandleClick} className='button' variant='outlined'>Criar Evento</Button>
                      </S.ButtonContainer>
                    </form>
                  </S.FormContainer>
                </S.ColumnContainer>
                <S.ColumnContainer style={{ justifyContent: 'center' }} width={40}>
                  {
                    event && (
                      <>
                        <S.RowContainer style={{ justifyContent: 'center' }}>
                          <S.ColumnContainer width={100}>
                            <p className='emphasis'>Evento em Atividade:</p>
                            <p className='information'>{event.label}</p>
                          </S.ColumnContainer>
                        </S.RowContainer>
                        <S.RowContainer style={{ justifyContent: 'center' }}>
                          <S.ColumnContainer width={100}>
                            <p className='emphasis'>Início: <span className='information'>{dayjs(event.initialDate).format('DD/MM/YYYY')}</span></p>
                            <p className='emphasis'>Término: <span className='information'>{dayjs(event.finalDate).format('DD/MM/YYYY')}</span></p>
                          </S.ColumnContainer>
                        </S.RowContainer>
                      </>
                    )
                  }
                  <S.RowContainer style={{ justifyContent: 'center' }}>
                    <S.ButtonContainer>
                      <Button onClick={handleConfigurationClick} className='button' variant='outlined'>Continuar para Configurações do Evento</Button>
                    </S.ButtonContainer>
                  </S.RowContainer>
                </S.ColumnContainer>
              </S.RowContainer>
            )
        }
      </S.ScreenContainer>
    </S.Container>
  )
}
