import { styled } from 'styled-components'

export const RowCOntainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee3;
  border-bottom: 1px solid #3b81f7;
  //box-shadow: 1px 0 3px #acacac33;
  margin-bottom: 10px;
  h3 {
    margin: 1px 3px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  p {
    color: #666;
    margin: 2px 3px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .icon {
    color: #222;
  }
  .date {
    font-size: 13px;
    align-self: flex-end;
  }
`
export const ColumnContainer = styled.div`
  width: 89%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
`
export const IconCOntainer = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
   width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 7px;
    &:hover {
      background-color:  #eee3;
    }
  }
`
export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TitleContainer = styled.div`
  width: 79%;
`
export const DateContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
