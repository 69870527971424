import React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import * as S from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import eventLogo from '../../assets/SELO.png'
import { ListLinksUser } from '../ListLinksUser'
import { ContainerMain } from '../ContainerMain'
import { ContainerAppBar } from '../ContainerAppBar'
import { ContainerHeader } from '../ContainerHeader'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { useNavigate } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Logout from '@mui/icons-material/Logout'
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import { setLoginUser, setUser } from '../../redux/actions/user.action'
import { urlFile } from '../../utils/urlFile.util'

interface Props {
  children: React.ReactNode
}

export const ContainerUser = ({ children }: Props): JSX.Element => {

  const theme = useTheme()
  const event = useSelector((state: RootState) => state.event.data)
  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const drawerWidth = 250
  const navigate = useNavigate()

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
  const [open, setOpen] = React.useState(screenWidth > 600)
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [fullName, setFullName] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const [eventImg, setEventImg] = React.useState<string>('')

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      if (!event) {

        const response = await backEnd('GET', 'events/default')

        if (!response.ok) {
          dispatch(setOpenGlobalMessageAction({
            message: response.msg,
            modal: {
              title: 'Ops...'
            }
          }))
        }
      }
    }

    void getData()
  }, [dispatch, event])

  React.useEffect(() => {

    const updateScreenWidth = (): void => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  React.useEffect(() => {
    const getUserData = async (): Promise<void> => {
      if (user.perfil?.id) {
        setLoading(true)
        const response = await backEnd('GET', `users/${user.perfil?.id}`)
        setLoading(false)
        if (!response.ok) {
          dispatch(setOpenGlobalMessageAction({
            message: response.msg
          }))
          return
        }
        setEmail(response.data.email)
        setFullName(response.data.fullName)
      }
    }
    void getUserData()
  }, [user.perfil?.id, dispatch])

  React.useEffect(() => {
    const getImgLogo = async (): Promise<void> => {
      try {
        const imgLogo = await urlFile('addons/logo')
        if (imgLogo) setEventImg(imgLogo)
      } catch (e) {
        console.log((e as Error).message)
        setEventImg(eventLogo)
      }
    }
    void getImgLogo()
  }, [])

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  const onClickNotification = (): void => {
    navigate('/notificacoes')
  }

  const onClickHelp = (): void => {
    navigate('/ajuda')
  }

  const onClickDashboardMenu = (): void => {
    handleClose()
    navigate('/')
  }

  const onClickProfileMenu = (): void => {
    handleClose()
    navigate('/perfil')
  }

  const onClickLogoutMenu = (): void => {
    handleClose()
    dispatch(setLoginUser({
      isLogged: false,
      token: null
    }))

    dispatch(setUser(null))
    navigate('/login')
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <S.Container style={{ fontFamily: theme.typography.fontFamily }}>
      <Box sx={{ display: 'flex' }}>
        <ContainerAppBar drawerwidth={drawerWidth} position="fixed" open={open}>
          <Toolbar className='toolbar'>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {event?.label}
            </Typography>
            {
              (screenWidth > 878 && !user.perfil?.needInfos) && (
                <S.IconsContainer>
                  <S.SingleIconContainer onClick={onClickHelp}>
                    <HelpOutlineIcon className='smallerIcon' />
                  </S.SingleIconContainer>
                  <S.SingleIconContainer onClick={onClickNotification}>
                    <NotificationsNoneIcon className='smallerIcon' />
                  </S.SingleIconContainer>
                  <S.SingleIconContainer onClick={handleClick}>
                    <Avatar className='profile' />
                  </S.SingleIconContainer>
                </S.IconsContainer>
              )
            }
          </Toolbar>
        </ContainerAppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant={screenWidth > 600 ? 'persistent' : 'temporary'}
          anchor="left"
          onClose={handleDrawerClose}
          open={open}
        >
          <ContainerHeader>
            <S.LogoContainer>
              <img src={eventImg} width={'50%'} />
            </S.LogoContainer>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </ContainerHeader>
          <ListLinksUser handleDrawerClose={screenWidth < 600 ? handleDrawerClose : undefined} />
        </Drawer>
        <ContainerMain drawerwidth={drawerWidth} open={screenWidth > 600 ? open : true}>
          <ContainerHeader />
          {children}
        </ContainerMain>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <div>
                <S.MenuContainer>
                  <Avatar sx={{ bgcolor: 'rgba(94, 53, 177, 0.7)' }} className='avatar'>
                    {fullName.split(' ')[0][0]}
                  </Avatar>
                  <p>{fullName}</p>
                  <p className='email'>{email}</p>
                </S.MenuContainer>
                <MenuItem onClick={onClickDashboardMenu}>
                  <ListItemIcon>
                    <InsertChartOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
                <MenuItem onClick={onClickProfileMenu}>
                  <ListItemIcon>
                    <AssignmentIndOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Dados da Inscrição
                </MenuItem>
                <MenuItem onClick={onClickLogoutMenu}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Sair
                </MenuItem>
              </div>
            )
        }
      </Menu>
    </S.Container>
  )
}
